import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// Import Components
import MainLayout from "./Application/Router/MainLayout";

// import Toast Message
import { ToastContainer } from "react-toastify";

function App() {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <Router>
        <MainLayout />
      </Router>
    </>
  );
}

export default App;
