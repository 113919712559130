import * as type from "../type";

const initialState = {
  about: [],
  loading: false,
  error: "",
  selectedAbout:null,
};

const aboutUs = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_ABOUT_SUCCESS:
      return{
        ...state,
        selectedAbout: action?.payload
      }
      case type.CLEAR_ABOUT_STATE:
        return{
          ...state,
          selectedAbout:[],
        }
    case type.ABOUT_US_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.ABOUT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        about: action.payload,
      };

    case type.ABOUT_US_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.DELETE_ABOUT_US_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case type.DELETE_ABOUT_US_SUCCESS: {
        return {
          ...state,
          about: (state?.about?.data).filter((singleUser) => {
            return singleUser?.id !== action?.payload?.data
          }
          ),
          loading: true,
          error: "",
        };
        
      }
      case type.DELETE_ABOUT_US_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

    default:
      return state;
  }
};

export default aboutUs;
