import React, { useState } from "react";
import { Modal } from "./Modal";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import isEmpty from "../validation/isEmpty";
import { addUserData } from "../../../redux/actions/getUsers";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import moment from "moment";
import { uploadUserProfilePicture } from "../../Utils/Utils";

const AddUser = ({ show, onHide }) => {
  const dispatch = useDispatch();

  const initialValues = {
    username: "",
    skypeName: null,
    emailId: "",
    password: "",
    photo: "",
    address: "",
    userTypeId: 2,
    showPassword: false,
  };

  const [state, setState] = useState({ ...initialValues });
  const [userNameErr, setUserNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [skypeNameErr, setSkypeNameErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const validateNormalUserForm = () => {
    let isValid = true;

    if (state.skypeName !== "" && !/^[a-zA-Z0-9.:]+$/.test(state.skypeName)) {
      setSkypeNameErr("Enter proper skype name");
      isValid = false;
    }

    if (isEmpty(state.username)) {
      setUserNameErr("Please enter your user name");
      isValid = false;
    } else {
      if (!/^[a-zA-Z0-9_.@ ]+$/.test(state.username)) {
        setUserNameErr("Special character are not allowed");
        isValid = false;
      }
    }

    if (isEmpty(state.emailId)) {
      setEmailErr("Please enter your email address");
      isValid = false;
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.emailId)) {
        setEmailErr("Please enter valid email");
        isValid = false;
      }
    }

    if (isEmpty(state.password)) {
      setPasswordErr("Please enter your password");
      isValid = false;
    } else {
      if (!/[0-9]/.test(state.password)) {
        setPasswordErr("please enter at least one numeric digit letter");
        isValid = false;
      }
      if (!/[A-Z]/.test(state.password)) {
        setPasswordErr("please enter at least one uppercase letter");
        isValid = false;
      }
      if (!/[a-z]/.test(state.password)) {
        setPasswordErr("please enter at least one lowercase letter");
        isValid = false;
      }
      if (!/[$@!#_=%]/.test(state.password)) {
        setPasswordErr("please enter at least one special character letter");
        isValid = false;
      }
      if (state.password.length < 7) {
        setPasswordErr("Password should be 8 characters");
        isValid = false;
      }
    }

    return isValid;
  };

  const handleChange = (e) => {
    if (e.target.name === "username") {
      setUserNameErr("");
    }
    if (e.target.name === "skypeName") {
      setSkypeNameErr("");
    }
    if (e.target.name === "emailId") {
      setEmailErr("");
    }
    if (e.target.name === "password") {
      setPasswordErr("");
    }

    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleAddUser = (e) => {
    e.preventDefault();

    const isValid = validateNormalUserForm();
    if (isValid) {
      const data = { ...state, photoUrl: state.photo };
      delete data.photo;
      dispatch(addUserData(data));
      handleClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
    if (type === "password") {
      setType("text");
      setIcon(eye);
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleClose = () => {
    dispatch({ type: "CLEAR_USERS_STATE" });
    setState("");
    setUserNameErr("");
    setPasswordErr("");
    setEmailErr("");
    setSkypeNameErr("");
    onHide();
  };

  const handleProfileChange = (e) => {
    setLoader(true);
    const file = e.target.files[0];
    uploadUserProfilePicture(setLoader, state, setState, file);
  };

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="Add User Details"
        footer={
          <>
            <Button onClick={handleAddUser}>Add</Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </>
        }
      >
        <form id="userForm" method="put" onSubmit={handleAddUser}>
          <div className="mb-4">
            <label className="form-label">Username</label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="username"
              placeholder="Enter Username"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            {userNameErr && <span className="error-msg">{userNameErr}</span>}
          </div>
          <div className="mb-4">
            <label className="form-label">Skype Name</label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="skypeName"
              placeholder="Enter Skype Name"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            {skypeNameErr && <span className="error-msg">{skypeNameErr}</span>}
          </div>
          <div className="mb-4">
            <label className="form-label">Email Address</label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="emailId"
              placeholder="Enter Email Address"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            {emailErr && <span className="error-msg">{emailErr}</span>}
          </div>
          <div className="mb-4">
            <label className="form-label">Password</label>
            <div className="password-conatiner">
              <input
                className="form-control form-control-lg"
                type={state?.showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={state?.password}
                autocomplete="new-password"
              />
              <span
                className="pass-show-icon"
                onClick={handleClickShowPassword}
              >
                <Icon icon={icon} />
              </span>
            </div>
            {passwordErr && <span className="error-msg">{passwordErr}</span>}
          </div>
          <div className="mb-4">
            <label className="form-label">Enter Address</label>
            <textarea
              className="form-control form-control-lg"
              type="text"
              value={state?.address === "null" ? "" : state?.address}
              name="address"
              placeholder="Enter Address"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4 fit-image">
            <img src={state?.photo} />
            <div className="input_file">
              <input
                className="edit-file"
                type="file"
                id="file"
                accept="image/*"
                name="photo"
                onChange={handleProfileChange}
              />
              Upload Image
            </div>
            {loader && (
              <>
                <div className="cssloader_main">
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUser;
