import React, { useEffect, useState, useRef } from "react";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProjects,
  selectedProject,
} from "../../../redux/actions/projects";
import _get from "lodash/get";
import ExportExcel from "../../Utils/ExportExcel";
import axios from "axios";
import DeleteModal from "../Modals/DeleteModal";
import { deleteProjectData } from "../../../redux/actions/projects";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewProject from "../Modals/ViewProject";
import moment from "moment";
import { pick as _pick, map as _map } from "lodash";

function Projects() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShowModal] = useState(false);
  const [shows, setDeleteModal] = useState(false);
  const [rowUserId, setRowUserId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [data, setData] = useState([]);
  const prevRef = useRef();
  const dispatch = useDispatch();
  const AllStars = useSelector((state) => state?.projects?.projects);

  const getHeaders = () => {
    const token = localStorage.getItem("token");
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "No-Auth-Challenge": true,
      },
    };
  };

  useEffect(() => {
    prevRef.current = { page, pageSize };
    dispatch(
      getAllProjects({
        pageSize: pageSize,
        pageNumber: page,
        startdate: "",
        enddate: "",
        search: "",
      })
    );
    (async () => {
      const user = await axios.get(
        "https://apiyouni.rlogical.com/api/Projects/GetProjects",
        getHeaders()
      );
      setData(user?.data?.data);
    })();
  }, [page, pageSize]);

  const downloadData = _map(data, (obj) =>
    _pick(obj, [
      "projectId",
      "projectName",
      "startDateTime",
      "endDateTime",
      "alarmDateTime",
      "description",
      "note",
      "priority",
    ])
  );

  const deleteUserData = () => {
    dispatch(
      deleteProjectData(rowUserId, {
        pageSize: pageSize,
        pageNumber: page,
        startdate: "",
        enddate: "",
        search: "",
      })
    );
    setDeleteModal(false);
  };

  const selectedProjectData = (row) => {
    dispatch(selectedProject(row));
    setShowModal(true);
  };

  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(
      getAllProjects({
        pageSize: pageSize,
        pageNumber: page,
        startdate: "",
        enddate: "",
        search: value,
      })
    );
  };

  const handleDateSearch = (event) => {
    if (startDate && endDate !== "") {
      dispatch(
        getAllProjects({
          pageSize: pageSize,
          pageNumber: page,
          startdate: moment(startDate).format("YYYY-MM-DD"),
          enddate: moment(endDate).format("YYYY-MM-DD"),
          search: "",
        })
      );
    }
  };

  const columns = [
    {
      name: "Project Name",
      selector: (row) => row.projectName,
      sortable: false,
    },
    {
      name: "Created username",
      selector: (row) => row.username,
      sortable: false,
      width: "160px",
    },
    {
      name: "Start DateTIme",
      selector: (row) =>
        row.startDateTime && moment(row.startDateTime).format("DD-MM-YYYY"),
      sortable: false,
    },
    {
      name: "End DateTime",
      selector: (row) =>
        row.endDateTime && moment(row.endDateTime).format("DD-MM-YYYY"),
      sortable: false,
    },
    {
      name: "Alarm DateTime",
      selector: (row) =>
        row.alarmDateTime && moment(row.alarmDateTime).format("DD-MM-YYYY"),
      sortable: false,
    },
    {
      name: "Priority",
      selector: (row) => row.priority,
      sortable: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            onClick={() => selectedProjectData(row)}
            type="button"
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          {/* <span

            type="button"
            className="delete-cls"

            id={row.projectId}
            onClick={() => { setDeleteModal(true); setRowUserId(row.projectId) }
            }
            data-mode="delete"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="align-middle"
              size="lg"
              border
            />
          </span> */}
        </>
      ),
    },
  ];

  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Projects</strong> Listing
              </h1>
            </div>
            <div className="col-auto  d-sm-block data">
              <p className="start">
                Start Date :
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </p>
              <p>
                End Date :
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </p>
              <button className="search-button" onClick={handleDateSearch}>
                Search
              </button>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />
              <ExportExcel data={downloadData} fileName="Projects" />
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={AllStars?.data}
              onChangeRowsPerPage={(newPageSize) => setPageSize(newPageSize)}
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(newPage) => setPage(newPage)}
              pagination
              paginationTotalRows={AllStars?.totalRecords}
              paginationServer
              loading={
                AllStars?.length === 0 ||
                _get(prevRef, "current.page", 0) !== page ||
                _get(prevRef, "current.pageSize", 10) !== pageSize
              }
              responsive
            />
          </div>
        </div>
      </main>
      <ViewProject show={show} onHide={() => setShowModal(!show)} />
      <DeleteModal
        show={shows}
        deleteUserData={deleteUserData}
        onHide={() => setDeleteModal(!shows)}
      />
    </>
  );
}

export default Projects;
