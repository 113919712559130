import * as type from "../type";

const initialState = {
  admin: [],
  loading: false,
  isLogin: false,
  error: "",
};

const userLogin = (state = initialState, action) => {
  switch (action.type) {
    case type.ADMIN_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        isLogin: false,
      };

    case type.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        admin: action.payload,
      };

    case type.ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        isLogin: false,
        error: action.message,
      };

    default:
      return state;
  }
};

export default userLogin;
