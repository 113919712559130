import React from "react";
import { Modal } from "./Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

const ViewCorporateCompany = ({ show, onHide }) => {

  const selectData = useSelector((state) => state?.corporateCompany?.selectedCompany);

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View Corporate Company Details"
        footer={<Button onClick={onHide}>Cancel</Button>}
      >
        <div className="center">
          <p> Company Id : {selectData?.companyId}</p>
          <p> Company Name : {selectData?.companyName}</p>
          <p> Corporate Id : {selectData?.corporateId}</p>
          <p> Description : {selectData?.description}</p>
          <p> Maximum user : {selectData?.maxUsers}</p>
          <p> Active user : {selectData?.activeUserCount}</p>
        </div>
      </Modal>
    </>
  );
};

export default ViewCorporateCompany;
