import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import ViewApplication from "../Modals/ApplicationPageModal/ViewApplication";
import AddApplicationPage from "../Modals/ApplicationPageModal/AddApplicationPage";
import DeleteModal from "../Modals/DeleteModal";
import {
  deleteApplicationPageData,
  getAllApplicationPagesData,
  selectedApplicationPage,
} from "../../../redux/actions/applicationPage";

const ApplicationPage = () => {
  const [addPages, setAddPages] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
  });
  const [show, setShowModal] = useState(false);
  const [edit, setEditModal] = useState(false);
  const [shows, setDeleteModal] = useState(false);
  const [rowUserId, setRowUserId] = useState("");
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const ApplicationPageData = useSelector(
    (state) => state?.applicationPage?.pages
  );

  useEffect(() => {
    dispatch(getAllApplicationPagesData(pagination));
  }, [pagination]);

  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(getAllApplicationPagesData({ ...pagination, search: value }));
  };

  const selectedApplicationPageData = (row) => {
    dispatch(selectedApplicationPage(row));
    setShowModal(true);
  };

  const addApplicationPageData = (row) => {
    setAddPages(true);
    dispatch(selectedApplicationPage(row));
  };

  const deleteUserData = () => {
    dispatch(
      deleteApplicationPageData(rowUserId, { ...pagination, search: "" })
    );
    setDeleteModal(false);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: false,
    },
    {
      name: "Video",
      selector: (row) => <video src={row.videoUrl} />,
      sortable: false,
    },
    {
      name: "Application Page",
      selector: (row) => row.description,
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) => (row?.isActive === true ? "Active" : "InActive"),
      sortable: false,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdDate).format("DD-MM-YYYY"),
      sortable: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            type="button"
            onClick={() => selectedApplicationPageData(row)}
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            className="edit-cls"
            type="button"
            onClick={() => addApplicationPageData(row)}
            id={row.userid}
            data-mode="edit"
          >
            <FontAwesomeIcon
              icon={faEdit}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            type="button"
            className="delete-cls"
            id={row.id}
            onClick={() => {
              setDeleteModal(true);
              setRowUserId(row?.id);
            }}
            data-mode="delete"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
  ];
  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Application Page</strong> Listing
              </h1>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />{" "}
              <button
                className="btn btn-primary"
                data-mode="add"
                onClick={addApplicationPageData}
              >
                Add
              </button>
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={ApplicationPageData.data}
              onChangeRowsPerPage={(newPageSize) =>
                setPagination({ ...pagination, pageSize: newPageSize })
              }
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(newPage) =>
                setPagination({ ...pagination, pageNumber: newPage })
              }
              pagination
              paginationTotalRows={ApplicationPageData?.totalRecords}
              paginationServer
              // selectableRows
              responsive
            />
          </div>
        </div>
      </main>
      <ViewApplication show={show} onHide={() => setShowModal(!show)} />
      <AddApplicationPage
        show={addPages}
        onHide={() => setAddPages(!addPages)}
      />
      <DeleteModal
        deleteUserData={deleteUserData}
        show={shows}
        onHide={() => setDeleteModal(!shows)}
      />
    </>
  );
};

export default ApplicationPage;
