import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../Modals/DeleteModal";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import {
  deleteStaffData,
  getAllStaff,
  selectedStaff,
} from "../../../redux/actions/staff";
import ViewStaff from "../Modals/StaffModal/ViewStaff";
import AddStaff from "../Modals/StaffModal/AddStaff";
import { useNavigate } from "react-router-dom";
import { pick as _pick, map as _map, get as _get } from "lodash";
import axios from "axios";
import ExportExcel from "../../Utils/ExportExcel";

const Staff = () => {
  const [addStaff, setAddStaff] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
  });
  const [show, setShowModal] = useState(false);
  const [shows, setDeleteModal] = useState(false);
  const [rowUserId, setRowUserId] = useState("");
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const StaffData = useSelector((state) => state?.staff?.staff);

  const getHeaders = () => {
    const token = localStorage.getItem("token");
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "No-Auth-Challenge": true,
      },
    };
  };

  useEffect(() => {
    dispatch(getAllStaff(pagination));
    (async () => {
      const user = await axios.get(
        "https://apiyouni.rlogical.com/api/StaffManagement/getAllStaff",
        getHeaders()
      );
      setData(user?.data?.data);
      dispatch({ type: "CLEAR_STAFF_STATE" });
    })();
  }, [pagination]);

  const downloadData = _map(data, (obj) =>
    _pick(obj, [
      "staffId",
      "userName",
      "dob",
      "emailId",
      "mobileNo",
      "gender",
      "education",
      "designation",
      "isActive",
    ])
  );

  const selectedStaffData = (row) => {
    dispatch(selectedStaff(row));
    setShowModal(true);
  };

  const deleteUserData = () => {
    dispatch(deleteStaffData(rowUserId, { ...pagination, search: "" }));
    setDeleteModal(false);
  };

  const editStaffData = (row) => {
    setAddStaff(true);
    dispatch(selectedStaff(row));
  };

  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(getAllStaff({ ...pagination, search: value }));
  };

  const handleAssign = (row) => {
    navigate(`/staff/${_get(row, "staffId", null)}`);
    dispatch(selectedStaff(row));
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.staffId,
      sortable: false,
      width: "80px",
    },
    {
      name: "Username",
      selector: (row) => row.userName,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.emailId,
      sortable: false,
      width: "180px",
    },
    {
      name: "Mobile",
      selector: (row) => row.mobileNo,
      sortable: false,
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      sortable: false,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => (row.isActive === true ? "Active" : "InActive"),
      sortable: false,
    },
    {
      name: "Action",
      width: "120px",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            type="button"
            onClick={() => selectedStaffData(row)}
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            className="edit-cls"
            type="button"
            onClick={() => {
              editStaffData(row);
            }}
            id={row.userid}
            data-mode="edit"
          >
            <FontAwesomeIcon
              icon={faEdit}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            type="button"
            className="delete-cls"
            id={row.id}
            onClick={() => {
              setDeleteModal(true);
              setRowUserId(row?.staffId);
            }}
            data-mode="delete"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
    {
      name: "Assign",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            type="button"
            onClick={() => handleAssign(row)}
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Staff</strong> Listing
              </h1>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />
              <ExportExcel data={downloadData} fileName="Staff_list" />
              <button
                className="btn btn-primary"
                data-mode="add"
                onClick={() => setAddStaff(true)}
              >
                Add
              </button>
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={StaffData.data}
              onChangeRowsPerPage={(newPageSize) =>
                setPagination({ ...pagination, pageSize: newPageSize })
              }
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(newPage) =>
                setPagination({ ...pagination, pageNumber: newPage })
              }
              pagination
              paginationTotalRows={StaffData?.totalRecords}
              paginationServer
              responsive
            />
          </div>
        </div>
      </main>
      <ViewStaff show={show} onHide={() => setShowModal(!show)} />
      <AddStaff show={addStaff} onHide={() => setAddStaff(!addStaff)} />
      <DeleteModal
        deleteUserData={deleteUserData}
        show={shows}
        onHide={() => setDeleteModal(!shows)}
      />
    </>
  );
};

export default Staff;
