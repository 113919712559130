import * as type from "../type";

const initialState = {
    roles: [],
    allRoles: [],
    loading: false,
    error: "",
    selectedRole: null,
    selectedAllRole: null,
};

const roleRights = (state = initialState, action) => {
    switch (action.type) {
        case type.SELECTED_ROLE_RIGHTS_SUCCESS:
            return {
                ...state,
                selectedRole: action?.payload
            }
            case type.SELECTED_ALL_ROLE_RIGHTS_SUCCESS:
            return {
                ...state,
                selectedAllRole: action?.payload
            }
        case type.ROLE_RIGHTS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case type.ROLE_RIGHTS_SUCCESS:
            return {
                ...state,
                loading: false,
                isLogin: true,
                roles: action.payload,
            };

        case type.ROLE_RIGHTS_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                isLogin: true,
                allRoles: action.payload,
            };

        case type.ROLE_RIGHTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            };

        case type.DELETE_ROLE_RIGHTS_REQUEST: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }
        case type.DELETE_ROLE_RIGHTS_SUCCESS: {
            return {
                ...state,
                roles: (state?.roles?.data).filter((singleUser) => {
                    return singleUser?.staffId !== action?.payload?.data
                }
                ),
                loading: true,
                error: "",
            };

        }
        case type.DELETE_ROLE_RIGHTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }


        default:
            return state;
    }
};

export default roleRights;
