import * as type from "../type";

const initialState = {
  projects: [],
  loading: false,
  error: "",
  selectedProject:null,
  UpcomingProjects: [],
};

const projects = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_PROJECT_SUCCESS:
      return{
        ...state,
        selectedProject: action?.payload
      }
    case type.PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        projects: action.payload,
      };

    case type.PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

      case type.UPCOMING_PROJECTS_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case type.UPCOMING_PROJECTS_SUCCESS:
        return {
          ...state,
          loading: false,
          isLogin: true,
          UpcomingProjects: action.payload,
        };
  
      case type.UPCOMING_PROJECTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.message,
        };

      case type.DELETE_PROJECT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case type.DELETE_PROJECT_SUCCESS: {
        return {
          ...state,
          projects: (state?.projects?.data).filter((singleUser) => {
            return singleUser?.projectId !== action?.payload?.data
          }
          ),
          loading: true,
          error: "",
        };
        
      }
      case type.DELETE_PROJECT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

    default:
      return state;
  }
};

export default projects;
