import React from "react";
import { Modal } from "../Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";

const ViewStaff = ({ show, onHide }) => {

    const selectData = useSelector((state) => state?.staff?.selectedStaff);
    const dispatch = useDispatch();

    const handleClose = () => {
      dispatch({ type: "CLEAR_STAFF_STATE" })
      onHide();
    }

    return (
        <>
            <Modal
                show={show}
                className="modal fade theme_modal follow"
                onHide={onHide}
                title="View Staff Details"
                footer={<Button onClick={handleClose}>Cancel</Button>}
            >
                <div className="center">
                    <p> User Id : {selectData?.staffId}</p>
                    <p> Username : {selectData?.userName}</p>
                    <p> DOB : {selectData?.dob && moment(selectData?.dob).format('DD-MM-YYYY')}</p>
                    <p> Email : {selectData?.emailId}</p>
                    <p> Mobile : {selectData?.mobileNo}</p>
                    <p> Gender : {selectData?.gender}</p>
                    <p> Education : {selectData?.education}</p>
                    <p> Designation : {selectData?.designation}</p>
                    <p> Status : {selectData?.isActive === true ? "Active" : "InActive"}</p>
                </div>
            </Modal>
        </>
    )
}

export default ViewStaff