import * as type from "../type";

const initialState = {
  community: [],
  loading: false,
  error: "",
  selectedCommunity:null,
};

const ourCommunity = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_COMMUNITY_SUCCESS:
      return{
        ...state,
        selectedCommunity: action?.payload
      }
    case type.CLEAR_COMMUNITY_STATE:
      return{
        ...state,
        selectedCommunity: [],
      }
    case type.COMMUNITY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.COMMUNITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        community: action.payload,
      };

    case type.COMMUNITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.DELETE_COMMUNITY_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case type.DELETE_COMETS_SUCCESS: {
        return {
          ...state,
          community: (state?.community?.data).filter((singleUser) => {
            return singleUser?.id !== action?.payload?.data
          }
          ),
          loading: true,
          error: "",
        };
        
      }
      case type.DELETE_COMMUNITY_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

    default:
      return state;
  }
};

export default ourCommunity;
