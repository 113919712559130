import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {getAllRoleDetails} from "../../../redux/actions/roleRights"
import moment from 'moment';
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";

const RolesAndRights = () => {

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllRoleDetails());
    }, []);

    const RolesData = useSelector((state) => state?.roleRights?.allRoles);

    const columns = [
        {
            name: "Id",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Staff Name",
            selector: (row) => row.staffName,
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => row.isActive === true ? "Active" : "InActive",
            sortable: true,
        },
        {
            name: "Date",
            selector: (row) => moment(row.createdDate).format('DD-MM-YYYY'),
            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <>
                    <span
                        className="view-cls"
                        type="button"
                        // onClick={() => selectedTermsData(row)}
                        id={row.id}
                        data-mode="view"
                    >
                        <FontAwesomeIcon
                            icon={faEye}
                            className="align-middle"
                            size="lg"
                            border
                        />
                    </span>
                    <span
                        className="edit-cls"
                        type="button"
                        // onClick={() => { editTermsData(row) }}
                        id={row.userid}
                        data-mode="edit"
                    >
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="align-middle"
                            size="lg"
                            border
                        />
                    </span>
                    <span
                        type="button"
                        className="delete-cls"
                        id={row.id}
                        // onClick={() => {
                        //     setDeleteModal(true);
                        //     setRowUserId(row?.id);
                        // }}
                        data-mode="delete"
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="align-middle"
                            size="lg"
                            border
                        />
                    </span>
                </>
            ),
        },
    ];

    return (
        <>
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="row mb-2 mb-xl-3">
                        <div className="col-auto d-none d-sm-block">
                            <h1 className="h3 mb-3">
                                <strong>Terms and Condition</strong> Listing
                            </h1>
                        </div>
                        <div className="col-auto ms-auto text-end mt-n1">
{/* 
                            <input
                                type="text"
                                placeholder="Search here..."
                                autoFocus
                              value={searchText}
                              onChange={(event) => requestSearch(event)}
                            />{" "} */}
                            <button
                                className="btn btn-primary"
                                data-mode="add"
                            // onClick={() => setAddTerms(true)}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <DataTable
                            columns={columns}
                            data={RolesData?.data}
                            onChangeRowsPerPage={(newPageSize) => setPageSize(newPageSize)}
                            paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                            onChangePage={(newPage) => setPage(newPage)}
                            pagination
                            paginationTotalRows={RolesData?.totalRecords}
                            paginationServer
                            selectableRows
                            responsive
                        />
                    </div>
                </div>
            </main>
            {/* <AddTerms show={addTerms} onHide={() => setAddTerms(!addTerms)} /> */}
            {/* <ViewTerms show={show} onHide={() => setShowModal(!show)} /> */}
            {/* <EditTerms show={edit} onHide={() => setEditModal(!edit)} /> */}
            {/* <DeleteModal deleteUserData={deleteUserData} show={shows} onHide={() => setDeleteModal(!shows)} /> */}
        </>
    )
}

export default RolesAndRights