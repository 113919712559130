import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { toast } from "react-toastify";
import { storage } from "../../firebase-config";

export const findDataById = (data, id) => {
  return data.find((element) => {
    return element.id.toString() === id;
  });
};

/**
 * @param {function} handleLoader // manage loader function
 * @param {object} state // state variable
 * @param {function} manageState // manage state function
 * @param {file} file // upload file object or value
 */
export const uploadUserProfilePicture = (
  handleLoader,
  state,
  manageState,
  file
) => {
  if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
    toast.error("This file format is not supported");
    handleLoader(false);
  } else {
    file["id"] = Math.random();
    const promises = [];
    const storageRef = ref(storage, `/profile_photo/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    promises.push(uploadTask);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          let URLS = url;
          if (URLS) {
            handleLoader(false);
          }
          manageState({ ...state, ["photo"]: URLS });
        });
      }
    );
  }
};
