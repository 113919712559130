import { getStaffData, addStaff, updateStaff, deleteStaff, addAssign, updateAssign } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestStaffData = () => {
  return {
    type: type.STAFF_REQUEST,
  };
};

const successStaff = (staff) => {
  return {
    type: type.STAFF_SUCCESS,
    payload: staff,
  };
};

const errorStaff = (error) => {
  return {
    type: type.STAFF_FAILURE,
    message: error,
  };
};

export const selectedStaff = (staff) => {
  return {
    type: type.SELECTED_STAFF_SUCCESS,
    payload: staff
  };
};

export const getAllStaff = (page) => {
  return function (dispatch) {
    dispatch(requestStaffData());
    getStaffData(page)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successStaff(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorStaff(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(error);
      });
  };
};

export const addStaffData = (body) => {
  return (dispatch) => {
    return addStaff(body)
      .then((res) => {
        toast.success("Staff Added Successfully");
        dispatch(getAllStaff({ pageNumber: 1, pageSize: 10, search: "" }))
   
      })
      .catch((err) => {});
  };
};

export const addAssignData = (body) => {
  return (dispatch) => {
    return addAssign(body)
      .then((res) => {
        toast.success("Staff Assign Updated Successfully");
        dispatch(getAllStaff())
   
      })
      .catch((err) => {});
  };
};

export const updatAssignData = (body) => {
  return (dispatch) => {
    return updateAssign(body)
      .then((res) => {
        toast.success("Staff Assign Updated Successfully");
        dispatch(getAllStaff({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {});
  };
};

export const updatStaffData = (body) => {
  return (dispatch) => {
    return updateStaff(body)
      .then((res) => {
        toast.success("Staff Updated Successfully");
        dispatch(getAllStaff({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {});
  };
};

export const deleteStaffData = (userDelete,pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_STAFF_REQUEST,
    });
    return deleteStaff(userDelete)
      .then((res) => {
        dispatch({
          type: type.DELETE_STAFF_SUCCESS,
          payload: res,
          data: userDelete,
        });
        toast.success("Staff Deleted Successfully");
        dispatch(getAllStaff(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_STAFF_FAILURE,
        });
      });
  };
};
