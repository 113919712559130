import { addPrivacy, deletePrivacy, getPrivacyAllData, updatePrivacy } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestPrivacyData = () => {
  return {
    type: type.PRIVAY_POLICY_REQUEST,
  };
};

const successPrivacy = (terms) => {
  return {
    type: type.PRIVAY_POLICY_SUCCESS,
    payload: terms,
  };
};

const errorPrivacy = (error) => {
  return {
    type: type.PRIVAY_POLICY_FAILURE,
    message: error,
  };
};

const addTermsDetail = (data) => ({
  type: type.ADD_USER,
  data,
});

export const selectedPrivacy = (data) => {
  return {
    type: type.SELECTED_PRIVACY_SUCCESS,
    payload: data
  };
};

const updateTermsDetail = (data) => ({
  type: type.UPDATE_PRIVACY,
  data,
});

export const getAllPrivacyData = (pageObj) => {
  return function (dispatch) {
    dispatch(requestPrivacyData());
    getPrivacyAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successPrivacy(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorPrivacy(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(error);
      });
  };
};

export const addPrivacyData = (body) => {
  return (dispatch) => {
    return addPrivacy(body)
      .then((res) => {
        toast.success("Privacy Policy Added Successfully");
        dispatch(getAllPrivacyData({ pageNumber: 1, pageSize: 10, search: "" }))
   
      })
      .catch((err) => {});
  };
};

export const updatePrivacyData = (body) => {
  return (dispatch) => {
    return updatePrivacy(body)
      .then((res) => {
        toast.success("Privacy Policy Updated Successfully");
        dispatch(getAllPrivacyData({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {});
  };
};

export const deletePrivacyData = (userDelete,pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_PRIVACY_REQUEST,
    });
    return deletePrivacy(userDelete)
      .then((res) => {
        dispatch({
          type: type.DELETE_PRIVACY_SUCCESS,
          payload: res,
          data: userDelete,
        });
        toast.success("Privacy Policy Deleted Successfully");
        dispatch(getAllPrivacyData(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_PRIVACY_FAILURE,
        });
      });
  };
};
