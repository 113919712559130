import React from "react";
import { Modal } from "../Modal"
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const ViewSubscription = ({ show, onHide }) => {
    const dispatch = useDispatch();
    const selectData = useSelector((state) => state?.subscription?.subscriptionUser?.data)
    const userData = useSelector((state) => state?.subscription?.selectedSubscription);

    const cancelData = () => {
        dispatch({type: "CLEAR_SUBSCRIPTION_DATA"})
        onHide()
    }

    return (
        <>
            <Modal
                show={show}
                className="modal fade theme_modal follow"
                onHide={onHide}
                title={`${userData?.username} Subscription Details`}
                footer={<Button onClick={cancelData}>Cancel</Button>}
            >
                {selectData?.map((plan) => {
                    return (
                        <>
                            <div className="center">
                                <p> User Id : {plan?.userId}</p>
                                <p> Plan Type : {plan?.subscriptionType}</p>
                                <p> Subscription Date : {plan?.startDate && moment(plan?.startDate).format('DD-MM-YYYY')}</p>
                                <p> Next Payment Date : {plan?.startDate && moment(plan?.nextPaymentDate).format('DD-MM-YYYY')}</p>
                                <p> Subscription Amount : {plan?.amount}</p>
                            </div>
                        </>
                    )
                })}
            </Modal>
        </>
    )
}

export default ViewSubscription