import * as type from "../type";

const initialState = {
  moons: [],
  loading: false,
  error: "",
};

const moons = (state = initialState, action) => {
  switch (action.type) {
    case type.MOONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.MOONS_SUCCESS:
      return {
        ...state,
        loading: false,
        moons: action.payload,
      };

    case type.MOONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    default:
      return state;
  }
};

export default moons;
