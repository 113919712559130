import React from "react";
import { Routes, Route } from "react-router-dom";

// Import Components
import PrivateRoute from "./../../Application/Components/Auth/PrivateRoute";
import PageNotFound from "./../../Application/Components/Auth/PageNotFound";
import Login from "./../../Application/Components/Auth/Login";
import Users from "../Components/Users/Users";
import Dashboard from "../Components/Content/Dashboard";
import CorporateCompany from "../Components/Corporate/CorporateCompany";
import CorporateUsers from "../Components/Corporate/CorporateUsers";
import Projects from "../Components/Projects/Projects";
import Comet from "../Components/Comet/Comet";
import ViewUserModal from "../Components/Modals/ViewUserModal";
import Contact from "../Components/Contact/Contact";
import TermsAndCondition from "../Components/TermsAndCondition/TermsAndCondition";
import AboutUs from "../Components/AboutUs/AboutUs";
import PrivacyPolicy from "../Components/PrivacyPolicy/PrivacyPolicy";
import Staff from "../Components/Staff/Staff";
import RolesAndRights from "../Components/RolesAndRights/RolesAndRights";
import AssignStaff from "../Components/Staff/AssignStaff";
import ApplicationPage from "../Components/ApplicationPage/ApplicationPage";
import Quotes from "../Components/Quotes/Quotes";
import OurBlogs from "../Components/OurBlogs/OurBlogs";
import OurCommunity from "../Components/OurCommunity/OurCommunity";
import Subscription from "../Components/Subscription/subscription";
import UpcomingProjects from "../Components/UpcomingProjects/UpcomingProjects";
import UpcomingComet from "../Components/UpcomingComet/UpcomingComet";

function MainLayout() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:id/action" element={<ViewUserModal />} />
          <Route path="/coroprate" element={<CorporateCompany />} />
          <Route path="/coroprate-user" element={<CorporateUsers />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/comets" element={<Comet />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/termsandconditions" element={<TermsAndCondition />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/staff/:id" element={<AssignStaff />} />
          <Route path="/rolesandrights" element={<RolesAndRights />} />
          <Route path="/applicationpages" element={<ApplicationPage />} />
          <Route path="/quotes" element={<Quotes />} />
          <Route path="/blogs" element={<OurBlogs />} />
          <Route path="/community" element={<OurCommunity />} />
          <Route path="/subscriptions" element={<Subscription />} />
          <Route path="/upcoming/projects" element={<UpcomingProjects />} />
          <Route path="/upcoming/comets" element={<UpcomingComet />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default MainLayout;
