import { addTerms, deleteTerms, getTermsAllData, updateTerms } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestTermsData = () => {
  return {
    type: type.TERMS_AND_CONDITION_REQUEST,
  };
};

const successTerms = (terms) => {
  return {
    type: type.TERMS_AND_CONDITION_SUCCESS,
    payload: terms,
  };
};

const errorTerms = (error) => {
  return {
    type: type.TERMS_AND_CONDITION_FAILURE,
    message: error,
  };
};

const addTermsDetail = (data) => ({
  type: type.ADD_USER,
  data,
});

export const selectedTerms = (data) => {
  return {
    type: type.SELECTED_TERMS_SUCCESS,
    payload: data
  };
};

const updateTermsDetail = (data) => ({
  type: type.UPDATE_TERMS,
  data,
});

export const getAllTermsData = (pageObj) => {
  return function (dispatch) {
    dispatch(requestTermsData());
    getTermsAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successTerms(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorTerms(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(error);
      });
  };
};

export const addTermsData = (body) => {
  return (dispatch) => {
    return addTerms(body)
      .then((res) => {
        toast.success("Terms and Condition Added Successfully");
        dispatch(getAllTermsData({ pageNumber: 1, pageSize: 10, search: "" }))
   
      })
      .catch((err) => {});
  };
};

export const updatTermsData = (body) => {
  return (dispatch) => {
    return updateTerms(body)
      .then((res) => {
        toast.success("Terms and Condition Updated Successfully");
        dispatch(getAllTermsData({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {});
  };
};

export const deleteTermsData = (userDelete,pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_TERMS_REQUEST,
    });
    return deleteTerms(userDelete)
      .then((res) => {
        dispatch({
          type: type.DELETE_TERMS_SUCCESS,
          payload: res,
          data: userDelete,
        });
        toast.success("Terms and Condition Deleted Successfully");
        dispatch(getAllTermsData(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_TERMS_FAILURE,
        });
      });
  };
};
