export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const CLEAR_USERS_STATE = "CLEAR_USERS_STATE";

export const SELECTED_USER_SUCCESS = "SELECTED_USER_SUCCESS";
export const SELECTED_COMPANY_SUCCESS = "SELECTED_COMPANY_SUCCESS";
export const SELECTED_CORPORATE_USER_SUCCESS =
  "SELECTED_CORPORATE_USER_SUCCESS";
export const SELECTED_COMET_SUCCESS = "SELECTED_COMET_SUCCESS";
export const SELECTED_PROJECT_SUCCESS = "SELECTED_PROJECT_SUCCESS";

export const ACTIVE_USER_REQUEST = "ACTIVE_USER_REQUEST";
export const ACTIVE_USER_SUCCESS = "ACTIVE_USER_SUCCESS";
export const ACTIVE_USER_FAILURE = "ACTIVE_USER_FAILURE";

export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const DELETE_SUBSCRIBED_USER_REQUEST = "DELETE_SUBSCRIBED_USER_REQUEST";
export const DELETE_SUBSCRIBED_USER_SUCCESS = "DELETE_SUBSCRIBED_USER_SUCCESS";
export const DELETE_SUBSCRIBED_USER_FAILURE = "DELETE_SUBSCRIBED_USER_FAILURE";

export const GET_CORPORATE_USERS_REQUEST = "GET_CORPORATE_USERS_REQUEST";
export const GET_CORPORATE_USERS_SUCCESS = "GET_CORPORATE_USERS_SUCCESS";
export const GET_CORPORATE_USERS_FAILURE = "GET_CORPORATE_USERS_FAILURE";
export const CLEAR_CORPORATE_USER_STATE = "CLEAR_CORPORATE_USER_STATE";

export const DELETE_CORPORATE_USERS_REQUEST = "DELETE_CORPORATE_USERS_REQUEST";
export const DELETE_CORPORATE_USERS_SUCCESS = "DELETE_CORPORATE_USERS_SUCCESS";
export const DELETE_CORPORATE_USERS_FAILURE = "DELETE_CORPORATE_USERS_FAILURE";

export const ADD_CORPORATE_REQUEST = "ADD_CORPORATE_REQUEST";
export const ADD_CORPORATE_SUCCESS = "ADD_CORPORATE_SUCCESS";
export const ADD_CORPORATE_FAILURE = "ADD_CORPORATE_FAILURE";

export const CORPORATE_COMPANY_REQUEST = "CORPORATE_COMPANY_REQUEST";
export const CORPORATE_COMPANY_SUCCESS = "CORPORATE_COMPANY_SUCCESS";
export const CORPORATE_COMPANY_FAILURE = "CORPORATE_COMPANY_FAILURE";
export const CLEAR_CORPORATE_COMPANY_STATE = "CLEAR_CORPORATE_COMPANY_STATE";

export const ACTIVE_COMPANY_REQUEST = "ACTIVE_COMPANY_REQUEST";
export const ACTIVE_COMPANY_SUCCESS = "ACTIVE_COMPANY_SUCCESS";
export const ACTIVE_COMPANY_FAILURE = "ACTIVE_COMPANY_FAILURE";

export const UPDATE_COMPANY = "UPDATE_COMPANY";

export const DELETE_CORPORATE_COMPANY_REQUEST =
  "DELETE_CORPORATE_COMPANY_REQUEST";
export const DELETE_CORPORATE_COMPANY_SUCCESS =
  "DELETE_CORPORATE_COMPANY_SUCCESS";
export const DELETE_CORPORATE_COMPANY_FAILURE =
  "DELETE_CORPORATE_COMPANY_FAILURE";

export const GET_USERS_COUNT_SUCCESS = "GET_USERS_COUT_SUCCESS";
export const GET_CORPORATE_USERS_COUNT_SUCCESS =
  "GET_CORPORATE_USERS_COUT_SUCCESS";

export const PROJECTS_REQUEST = "PROJECTS_REQUEST";
export const PROJECTS_SUCCESS = "PROJECTS_SUCCESS";
export const PROJECTS_FAILURE = "PROJECTS_FAILURE";

export const UPCOMING_PROJECTS_REQUEST = "UPCOMING_PROJECTS_REQUEST";
export const UPCOMING_PROJECTS_SUCCESS = "UPCOMING_PROJECTS_SUCCESS";
export const UPCOMING_PROJECTS_FAILURE = "UPCOMING_PROJECTS_FAILURE";

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";

export const PLANETS_REQUEST = "PLANETS_REQUEST";
export const PLANETS_SUCCESS = "PLANETS_SUCCESS";
export const PLANETS_FAILURE = "PLANETS_FAILURE";

export const MOONS_REQUEST = "MOONS_REQUEST";
export const MOONS_SUCCESS = "MOONS_SUCCESS";
export const MOONS_FAILURE = "MOONS_FAILURE";

export const SATELLITE_REQUEST = "SATELLITE_REQUEST";
export const SATELLITE_SUCCESS = "SATELLITE_SUCCESS";
export const SATELLITE_FAILURE = "SATELLITE_FAILURE";

export const COMETS_REQUEST = "COMETS_REQUEST";
export const COMETS_SUCCESS = "COMETS_SUCCESS";
export const COMETS_FAILURE = "COMETS_FAILURE";

export const UPCOMING_COMETS_REQUEST = "UPCOMING_COMETS_REQUEST";
export const UPCOMING_COMETS_SUCCESS = "UPCOMING_COMETS_SUCCESS";
export const UPCOMING_COMETS_FAILURE = "UPCOMING_COMETS_FAILURE";

export const DELETE_COMETS_REQUEST = "DELETE_COMETS_REQUEST";
export const DELETE_COMETS_SUCCESS = "DELETE_COMETS_SUCCESS";
export const DELETE_COMETS_FAILURE = "DELETE_COMETS_FAILURE";

export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAILURE = "CONTACT_US_FAILURE";
export const SELECTED_CONTACT_SUCCESS = "SELECTED_CONTACT_SUCCESS";

export const TERMS_AND_CONDITION_REQUEST = "TERMS_AND_CONDITION_REQUEST";
export const TERMS_AND_CONDITION_SUCCESS = "TERMS_AND_CONDITION_SUCCESS";
export const TERMS_AND_CONDITION_FAILURE = "TERMS_AND_CONDITION_FAILURE";
export const CLEAR_TERMS_STATE = "CLEAR_TERMS_STATE";
export const SELECTED_TERMS_SUCCESS = "SELECTED_TERMS_SUCCESS";
export const UPDATE_TERMS = "UPDATE_TERMS";
export const DELETE_TERMS_REQUEST = "DELETE_TERMS_REQUEST";
export const DELETE_TERMS_SUCCESS = "DELETE_TERMS_SUCCESS";
export const DELETE_TERMS_FAILURE = "DELETE_TERMS_FAILURE";

export const PRIVAY_POLICY_REQUEST = "PRIVAY_POLICY_REQUEST";
export const PRIVAY_POLICY_SUCCESS = "PRIVAY_POLICY_SUCCESS";
export const PRIVAY_POLICY_FAILURE = "PRIVAY_POLICY_FAILURE";
export const CLEAR_PRIVACY_STATE = "CLEAR_PRIVACY_STATE";
export const SELECTED_PRIVACY_SUCCESS = "SELECTED_PRIVACY_SUCCESS";
export const UPDATE_PRIVACY = "UPDATE_PRIVACY";
export const DELETE_PRIVACY_REQUEST = "DELETE_PRIVACY_REQUEST";
export const DELETE_PRIVACY_SUCCESS = "DELETE_PRIVACY_SUCCESS";
export const DELETE_PRIVACY_FAILURE = "DELETE_PRIVACY_FAILURE";

export const ABOUT_US_REQUEST = "ABOUT_US_REQUEST";
export const ABOUT_US_SUCCESS = "ABOUT_US_SUCCESS";
export const ABOUT_US_FAILURE = "ABOUT_US_FAILURE";
export const SELECTED_ABOUT_SUCCESS = "SELECTED_ABOUT_SUCCESS";
export const DELETE_ABOUT_US_REQUEST = "DELETE_ABOUT_US_REQUEST";
export const DELETE_ABOUT_US_SUCCESS = "DELETE_ABOUT_US_SUCCESS";
export const DELETE_ABOUT_US_FAILURE = "DELETE_ABOUT_US_FAILURE";

export const STAFF_REQUEST = "STAFF_REQUEST";
export const STAFF_SUCCESS = "STAFF_SUCCESS";
export const STAFF_FAILURE = "STAFF_FAILURE";
export const CLEAR_STAFF_STATE = "CLEAR_STAFF_STATE";
export const SELECTED_STAFF_SUCCESS = "SELECTED_STAFF_SUCCESS";
export const DELETE_STAFF_REQUEST = "DELETE_STAFF_REQUEST";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const DELETE_STAFF_FAILURE = "DELETE_STAFF_FAILURE";

export const ROLE_RIGHTS_REQUEST = "ROLE_RIGHTS_REQUEST";
export const ROLE_RIGHTS_SUCCESS = "ROLE_RIGHTS_SUCCESS";
export const ROLE_RIGHTS_FAILURE = "ROLE_RIGHTS_FAILURE";
export const SELECTED_ROLE_RIGHTS_SUCCESS = "SELECTED_ROLE_RIGHTS_SUCCESS";
export const SELECTED_ALL_ROLE_RIGHTS_SUCCESS =
  "SELECTED_ALL_ROLE_RIGHTS_SUCCESS";
export const DELETE_ROLE_RIGHTS_REQUEST = "DELETE_ROLE_RIGHTS_REQUEST";
export const DELETE_ROLE_RIGHTS_SUCCESS = "DELETE_ROLE_RIGHTS_SUCCESS";
export const DELETE_ROLE_RIGHTS_FAILURE = "DELETE_ROLE_RIGHTS_FAILURE";

export const ROLE_RIGHTS_ALL_SUCCESS = "ROLE_RIGHTS_ALL_SUCCESS";
export const CLEAR_STATE = "CLEAR_STATE";
export const CLEAR_ABOUT_STATE = "CLEAR_ABOUT_STATE";
export const CLEAR_COMMUNITY_STATE = "CLEAR_COMMUNITY_STATE";

export const APPLICATION_PAGE_REQUEST = "APPLICATION_PAGE_REQUEST";
export const APPLICATION_PAGE_SUCCESS = "APPLICATION_PAGE_SUCCESS";
export const APPLICATION_PAGE_FAILURE = "APPLICATION_PAGE_FAILURE";
export const CLEAR_APPLICATION_STATE = "CLEAR_APPLICATION_STATE";
export const SELECTED_APPLICATION_PAGE_SUCCESS =
  "SELECTED_APPLICATION_PAGE_SUCCESS";
export const DELETE_APPLICATION_PAGE_REQUEST =
  "DELETE_APPLICATION_PAGE_REQUEST";
export const DELETE_APPLICATION_PAGE_SUCCESS =
  "DELETE_APPLICATION_PAGE_SUCCESS";
export const DELETE_APPLICATION_PAGE_FAILURE =
  "DELETE_APPLICATION_PAGE_FAILURE";

export const QUOTES_REQUEST = "QUOTES_REQUEST";
export const QUOTES_SUCCESS = "QUOTES_SUCCESS";
export const QUOTES_FAILURE = "QUOTES_FAILURE";
export const CLEAR_QUOTE_STATE = "CLEAR_QUOTE_STATE";
export const SELECTED_QUOTES_SUCCESS = "SELECTED_QUOTES_SUCCESS";
export const DELETE_QUOTES_REQUEST = "DELETE_QUOTES_REQUEST";
export const DELETE_QUOTES_SUCCESS = "DELETE_QUOTES_SUCCESS";
export const DELETE_QUOTES_FAILURE = "DELETE_QUOTES_FAILURE";

export const BLOGS_REQUEST = "BLOGS_REQUEST";
export const BLOGS_SUCCESS = "BLOGS_SUCCESS";
export const BLOGS_FAILURE = "BLOGS_FAILURE";
export const CLEAR_BLOG_STATE = "CLEAR_BLOG_STATE";
export const SELECTED_BLOGS_SUCCESS = "SELECTED_BLOGS_SUCCESS";
export const DELETE_BLOGS_REQUEST = "DELETE_BLOGS_REQUEST";
export const DELETE_BLOGS_SUCCESS = "DELETE_BLOGS_SUCCESS";
export const DELETE_BLOGS_FAILURE = "DELETE_BLOGS_FAILURE";

export const COMMUNITY_REQUEST = "COMMUNITY_REQUEST";
export const COMMUNITY_SUCCESS = "COMMUNITY_SUCCESS";
export const COMMUNITY_FAILURE = "COMMUNITY_FAILURE";
export const SELECTED_COMMUNITY_SUCCESS = "SELECTED_COMMUNITY_SUCCESS";
export const DELETE_COMMUNITY_REQUEST = "DELETE_COMMUNITY_REQUEST";
export const DELETE_COMMUNITY_SUCCESS = "DELETE_COMMUNITY_SUCCESS";
export const DELETE_COMMUNITY_FAILURE = "DELETE_COMMUNITY_FAILURE";

export const SUBSCRIPTION_REQUEST = "SUBSCRIPTION_REQUEST";
export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS";
export const SUBSCRIPTION_FAILURE = "SUBSCRIPTION_FAILURE";
export const SELECTED_SUBSCRIPTION_SUCCESS = "SELECTED_SUBSCRIPTION_SUCCESS";
export const CLEAR_SUBSCRIPTION_DATA = "CLEAR_SUBSCRIPTION_DATA";

export const SUBSCRIPTION_USER_REQUEST = "SUBSCRIPTION_USER_REQUEST";
export const SUBSCRIPTION_USER_SUCCESS = "SUBSCRIPTION_USER_SUCCESS";
export const SUBSCRIPTION_USER_FAILURE = "SUBSCRIPTION_USER_FAILURE";
export const UPDATE_SUBSCRIPTION_STATUS = "UPDATE_SUBSCRIPTION_STATUS";
