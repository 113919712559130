import * as type from "../type";

const initialState = {
  corporateUsers: [],
  company: [],
  loading: false,
  error: "",
  selectedCompany: null,
  selectedCorporateUser: null,
};

const corporateCompany = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_COMPANY_SUCCESS:
      return {
        ...state,
        selectedCompany: action?.payload
      }
    case type.CLEAR_CORPORATE_COMPANY_STATE:
      return {
        ...state,
        selectedCompany: []
      }
    case type.SELECTED_CORPORATE_USER_SUCCESS:
      return {
        ...state,
        selectedCorporateUser: action?.payload
      }
    case type.CLEAR_CORPORATE_USER_STATE:
      return {
        ...state,
        selectedCorporateUser: []
      }
    case type.ADD_CORPORATE_REQUEST:
    case type.CORPORATE_COMPANY_REQUEST:
      return {
        //...state,
        loading: true,
        isLogin: false,
      };

    case type.CORPORATE_COMPANY_SUCCESS:
      return {
        // ...state,
        loading: true,
        company: action.payload,
      };
    case type.ADD_CORPORATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        corporateUsers: action.payload,
      };

    case type.ADD_CORPORATE_FAILURE:
    case type.CORPORATE_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        isLogin: false,
        error: action.message,
      };

    case type.DELETE_CORPORATE_COMPANY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case type.DELETE_CORPORATE_COMPANY_SUCCESS: {
      return {
        ...state,
        company: (state?.company?.data).filter((singleUser) => {
          return singleUser?.companyId !== action?.payload?.data
        }
        ),
        loading: true,
        error: "",
      };

    }
    case type.DELETE_CORPORATE_COMPANY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default corporateCompany;
