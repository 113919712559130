import React from "react";
import { Modal } from "../Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";

const ViewAbout = ({ show, onHide }) => {

  const selectData = useSelector((state) => state?.aboutUs?.selectedAbout);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: "CLEAR_ABOUT_STATE" })
    onHide();
  }

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View About Us Details"
        footer={<Button onClick={handleClose}>Cancel</Button>}
      >
        <div className="center">
          <p> Id : {selectData?.id}</p>
          <p> About : {selectData?.about}</p>
          <p> Date : {moment(selectData?.createdDate).format('DD-MM-YYYY')}</p>
          {selectData?.imageUrl &&
          <p>Image : <img src={selectData?.imageUrl} className="video-show" onClick={()=> window.open(selectData?.imageUrl, "_blank")}/><br /></p>}
          {selectData?.videoUrl &&
          <p >Video : <video controls className="video-show">
            <source src={selectData?.videoUrl} type="video/mp4"></source>
          </video><br /></p>}
        </div>
      </Modal>
    </>
  );
};

export default ViewAbout;
