import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react'
import moment from 'moment'


const ExportExcel = ({ data, fileName }) => {

    const getFileName = (fileExt) => {
        return `${fileName}_${moment().format('YYYYMMDD')}${fileExt}`
    }

    const exportExcel = () => {

        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, fileName);
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, getFileName(EXCEL_EXTENSION));
            }
        });
    }

    return (
        <>
            <span

                type="button"
                className="delete-cls"
                onClick={() => exportExcel()}
            >
                <FontAwesomeIcon
                    icon={faDownload}
                    className="align-middle"
                    size="lg"
                    border
                />
            </span>
        </>
    )
}

export default ExportExcel