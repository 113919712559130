import React from "react";
import { Modal } from "./Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

const ViewComet = ({ show, onHide }) => {

  const selectData = useSelector((state) => state?.comets?.selectedComet);

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View Comet Details"
        footer={<Button onClick={onHide}>Cancel</Button>}
      >
        <div className="center">
          <p> Comet Id : {selectData?.cometId}</p>
          <p> Comet Name : {selectData?.cometName}</p>
          <p> Description : {selectData?.description}</p>
          <p> Note : {selectData?.note}</p>
          <p> Priority : {selectData?.priority}</p>
        </div>
        {/* <Button>Cancel</Button> */}
      </Modal>
    </>
  );
};

export default ViewComet;
