import React from "react";
import { Modal } from "../Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";

const ViewApplication = ({ show, onHide }) => {

  const selectData = useSelector((state) => state?.applicationPage?.selectedPages);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: "CLEAR_APPLICATION_STATE" })
    onHide();
  }

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow common-popup-wrapper"
        onHide={onHide}
        title="View Application Details"
        footer={<Button onClick={handleClose}>Cancel</Button>}
      >
        <div className="center">
          <p> Id : {selectData?.id}</p>
          <p> Description : {selectData?.description}</p>
          <p> Date : {moment(selectData?.createdDate).format('DD-MM-YYYY')}</p>
          {selectData?.videoUrl &&
            <p>Video : <video controls className="video-show">
              <source src={selectData?.videoUrl} type="video/mp4"></source>
            </video><br /></p>
          }
        </div>
      </Modal>
    </>
  )
}

export default ViewApplication