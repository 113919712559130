import React, { useState, useEffect } from 'react'
import { Modal } from '../Modal'
import Button from 'react-bootstrap/Button';
import isEmpty from '../../validation/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { addQuotesData, updatQuotesData } from '../../../../redux/actions/quotes';

const AddQuotes = ({show,onHide}) => {

  const [quoteErr, setQuoteErr] = useState('')
  const initialValues = {
    quote: "",
    isActive: false,
  };

  const [state, setState] = useState({ ...initialValues })
  const dispatch = useDispatch();

  const selectData = useSelector((state) => state?.quotes?.selectedQuotes);

  useEffect(() => {
    setState(selectData);
  }, [selectData]);

  const handleChange = (e) => {

    if (e.target.name === "quote") {
      setQuoteErr("");
    }

    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;

    if (isEmpty(state?.quote)) {
      setQuoteErr("Please enter description");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid && !selectData?.quoteId) {
      const data = {
        quote: state?.quote,
        isActive: state?.isActive ? state?.isActive : false,
        createdBy: 0,
      }
      dispatch(addQuotesData(data))
      handleClose();
    } else if(isValid) {
      const data = {
        QuoteId: state?.quoteId,
        Quote: state?.quote,
        IsActive: state?.isActive,
      }
      const formData = new FormData();
      for (let key in data) {
        if (data[key] !== null) {
          formData.append(key, data[key]);
        }
      }
      dispatch(updatQuotesData(formData))
      handleClose()
    }

  }

  const handleClose = () => {
    dispatch({ type: "CLEAR_QUOTE_STATE" });
    setQuoteErr("")
    onHide();
  }

  const handleActive = (event) => {
    if (event.target.checked) {
      setState({ ...state, ["isActive"]: true });
    } else {
      setState({ ...state, ["isActive"]: false });
    }
  }

  return (
     <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title={state?.quoteId ? "Update Quote" : "Add Quote"}
        footer={
          <>
            <Button onClick={handleSubmit}>{state?.quoteId ? "Update" : "Add"}</Button>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          </>}
      >
        <label className="form-label">Quote Description </label>
        <textarea
          className='textarea'
          name="quote" rows="4" cols="93"
          placeholder='Please enter quote'
          value={state?.quote}
          onChange={handleChange}
        />
        {quoteErr && <span className="error-msg">{quoteErr}</span>}
        <br/>
        <label className="form-label">Active </label>{" "}
        <label class="switch">
          <input type="checkbox"
            checked={
              state?.isActive
                ? state?.isActive
                : false
            }
            onChange={handleActive} />
          <span class="slider round"></span>
        </label>
      </Modal>
    </>
  )
}

export default AddQuotes