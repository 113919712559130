import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import moment from "moment";
import { getAllUpcomingComet } from "../../../redux/actions/Comets";

const UpcomingComet = () => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
  });
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const UpcomingComets = useSelector((state) => state?.comets?.upcomingComet);

  useEffect(() => {
    dispatch(getAllUpcomingComet(pagination));
  }, [pagination]);

  const columns = [
    {
      name: "User Id",
      selector: (row) => row.createdBy,
      sortable: false,
    },
    {
      name: "Comet Name",
      selector: (row) => row.cometName,
      sortable: false,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: false,
    },
    {
      name: "Note",
      selector: (row) => row.note,
      sortable: false,
    },
    {
      name: "Created Date",
      selector: (row) => moment(row.createdDate).format("DD-MM-YYYY"),
      sortable: false,
    },
  ];

  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(getAllUpcomingComet({ ...pagination, search: value }));
  };

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row mb-2 mb-xl-3">
          <div className="col-auto d-none d-sm-block">
            <h1 className="h3 mb-3">
              <strong>Upcoming Comets</strong> Listing
            </h1>
          </div>
          <div className="col-auto ms-auto text-end mt-n1">
            <input
              type="text"
              placeholder="Search here..."
              autoFocus
              value={searchText}
              onChange={(event) => requestSearch(event)}
            />
          </div>
        </div>
        <div className="row">
          <DataTable
            columns={columns}
            data={UpcomingComets.data}
            onChangeRowsPerPage={(newPageSize) =>
              setPagination({ ...pagination, pageSize: newPageSize })
            }
            paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(newPage) =>
              setPagination({ ...pagination, pageNumber: newPage })
            }
            pagination
            paginationTotalRows={UpcomingComets?.totalRecords}
            paginationServer
            // selectableRows
            responsive
          />
        </div>
      </div>
    </main>
  );
};

export default UpcomingComet;
