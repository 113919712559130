import * as type from "../type";

const initialState = {
  terms: [],
  loading: false,
  error: "",
  selectedTerms: null,
};

const termsAndCondition = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_TERMS_SUCCESS:
      return {
        ...state,
        selectedTerms: action?.payload
      }
    case type.CLEAR_TERMS_STATE:
      return {
        ...state,
        selectedTerms: []
      }
    case type.TERMS_AND_CONDITION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.TERMS_AND_CONDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        terms: action.payload,
      };

    case type.TERMS_AND_CONDITION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    case type.DELETE_TERMS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case type.DELETE_TERMS_SUCCESS: {
      return {
        ...state,
        terms: (state?.terms?.data).filter((singleUser) => {
          return singleUser?.id !== action?.payload?.data
        }
        ),
        loading: true,
        error: "",
      };

    }
    case type.DELETE_TERMS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default termsAndCondition;
