import React from "react";
import { Modal } from "../Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";

const ViewQuotes = ({show,onHide}) => {

  const selectData = useSelector((state) => state?.quotes?.selectedQuotes);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: "CLEAR_QUOTE_STATE" })
    onHide();
  }

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View Quote Details"
        footer={<Button onClick={handleClose}>Cancel</Button>}
      >
        <div className="center">
          <p> Id : {selectData?.quoteId}</p>
          <p> Quotes : {selectData?.quote}</p>
          <p> Date : {moment(selectData?.createdDate).format('DD-MM-YYYY')}</p>
        </div>
      </Modal>
    </>
  )
}

export default ViewQuotes