import { addQuotes, deleteQuotes, getQuotesAllData, updateQuotes } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestQuotesData = () => {
  return {
    type: type.QUOTES_REQUEST,
  };
};

const successQuotes = (quotes) => {
  return {
    type: type.QUOTES_SUCCESS,
    payload: quotes,
  };
};

const errorQuotes = (error) => {
  return {
    type: type.QUOTES_FAILURE,
    message: error,
  };
};

export const selectedQuotes = (data) => {
  return {
    type: type.SELECTED_QUOTES_SUCCESS,
    payload: data
  };
};

export const getAllQuotesData = (pageObj) => {
  return function (dispatch) {
    dispatch(requestQuotesData());
    getQuotesAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successQuotes(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorQuotes(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(error);
      });
  };
};

export const addQuotesData = (body) => {
  return (dispatch) => {
    return addQuotes(body)
      .then((res) => {
        toast.success("Quotes Added Successfully");
        dispatch(getAllQuotesData({ pageNumber: 1, pageSize: 10, search: "" }))
   
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const updatQuotesData = (body) => {
  return (dispatch) => {
    return updateQuotes(body)
      .then((res) => {
        toast.success("Quotes Updated Successfully");
        dispatch(getAllQuotesData({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const deleteQuotesData = (userDelete) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_QUOTES_REQUEST,
    });
    return deleteQuotes(userDelete)
      .then((res) => {
        dispatch({
          type: type.DELETE_QUOTES_SUCCESS,
          payload: res,
          data: userDelete,
        });
        toast.success("Quotes Deleted Successfully");
        dispatch(getAllQuotesData({ pageNumber: 1, pageSize: 10, search: "" }));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_QUOTES_FAILURE,
        });
      });
  };
};

