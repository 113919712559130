import React, { useState, useEffect } from "react";
import { Modal } from "./Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { editCorporateCompany } from "../../../redux/actions/corporateUser";
import jwt_decode from "jwt-decode";
import moment from "moment";

const EditCorporateCompany = ({ show, onHide, editcompanyData }) => {
  const selectData = useSelector(
    (state) => state?.corporateCompany?.selectedCompany
  );
  const updateByData = jwt_decode(localStorage.getItem("token"));
  const dispatch = useDispatch();

  const initialValues = {
    corporateId: "",
    companyName: "",
    description: "",
    maxUsers: "",
    macAddress: "",
  };

  const [state, setState] = useState({ ...initialValues });
  const [corpoIdErr, setCorpoIdErr] = useState("");
  const [compErr, setCompErr] = useState("");
  const [descErr, setDescErr] = useState("");
  const [maxUserErr, setMaxUserErr] = useState("");

  useEffect(() => {
    setState(selectData);
  }, [selectData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "corporateId") {
      setCorpoIdErr("");
    }

    if (name === "companyName") {
      setCompErr("");
    }

    if (name === "description") {
      setDescErr("");
    }

    if (name === "maxUsers") {
      setMaxUserErr("");
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const formValidation = () => {
    let isValid = true;

    if (!state.corporateId) {
      setCorpoIdErr("Please enter corporate id");
      isValid = false;
    }

    if (!state.companyName) {
      setCompErr("Please enter compnay N\name");
      isValid = false;
    }

    if (!state.description) {
      setDescErr("Please enter description");
      isValid = false;
    }

    if (!state.maxUsers || state.maxUsers == 0) {
      setMaxUserErr("Please enter maximum user");
      isValid = false;
    }

    if (state.maxUsers > 100) {
      setMaxUserErr("Maximum user should be 100 or less");
      isValid = false;
    }

    return isValid;
  };

  const updateSelected = (userDetails) => {
    delete userDetails.deletedBy;
    delete userDetails.deletedDate;
    const formData = new FormData();
    for (let key in userDetails) {
      if (userDetails[key] !== null || userDetails[key] !== "null") {
        formData.append(key, userDetails[key]);
      }
    }
    dispatch(editCorporateCompany(formData, onHide));
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      const data = {
        ...state,
        updatedBy: updateByData.StaffId,
        updatedDate: moment().format(),
      };
      updateSelected(data);
    }
  };

  const handleClose = () => {
    dispatch({ type: "CLEAR_CORPORATE_COMPANY_STATE" });
    setState(initialValues);
    setCorpoIdErr("");
    setCompErr("");
    setDescErr("");
    setMaxUserErr("");
    onHide();
  };

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="Edit Corporate Company Details"
        footer={
          <>
            <Button onClick={handleUpdate}>Update</Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </>
        }
      >
        <form id="userForm" method="put" onSubmit={handleUpdate}>
          <div className="mb-4">
            <label className="form-label">Enter Corporate ID</label>
            <input
              className="form-control form-control-lg"
              type="text"
              value={state?.corporateId}
              name="corporateId"
              placeholder="Enter Corporate ID"
              autoComplete="off"
              onChange={handleChange}
            />
            {corpoIdErr && <span style={{ color: "red" }}>{corpoIdErr}</span>}
          </div>
          <div className="mb-4">
            <label className="form-label">Enter Company Name</label>
            <input
              className="form-control form-control-lg"
              type="text"
              value={state?.companyName}
              name="companyName"
              placeholder="Enter Company Name"
              autoComplete="off"
              onChange={handleChange}
            />
            {compErr && <span style={{ color: "red" }}>{compErr}</span>}
          </div>
          <div className="mb-4">
            <label className="form-label">Enter Description</label>
            <textarea
              className="form-control form-control-lg"
              type="text"
              value={state?.description}
              name="description"
              placeholder="Enter Description"
              autoComplete="off"
              onChange={handleChange}
            />
            {descErr && <span style={{ color: "red" }}>{descErr}</span>}
          </div>
          <div className="mb-4">
            <label className="form-label">Enter Maximum User</label>
            <input
              className="form-control form-control-lg"
              type="number"
              value={state?.maxUsers}
              name="maxUsers"
              placeholder="Enter Maximum User"
              autoComplete="off"
              onChange={handleChange}
              maxLength="3"
              pattern="^-?[0-9]\d*\.?\d*$"
            />
            {maxUserErr && <span style={{ color: "red" }}>{maxUserErr}</span>}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditCorporateCompany;
