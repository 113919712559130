import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllRole } from '../../../redux/actions/roleRights';
import { addAssignData, updatAssignData } from '../../../redux/actions/staff';

const AssignStaff = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const staffData = useSelector((state) => state?.staff?.selectedStaff)
    const roles = useSelector((state) => state?.roleRights?.roles?.data)

    const mainValues = {
        corporateUsers: false,
        about: false,
        dashboard: false,
        projects: false,
        advertisement: false,
        contactUs: false,
        termsCondition: false,
        comets: false,
        corporateCompany: false,
        applicationPage: false,
        cms: false,
        ourCommunity: false,
        privacyPolicy: false,
        quotes: false,
        socialPage: false,
        staff: false,
        subscription: false,
        technicalHelp: false,
        users: false,
        subscribedusers: false,
        upcomingProjects: false,
        upcomingComets: false,
        staffId: staffData?.staffId,
    };
    const [toggle, setToggle] = useState({ ...mainValues });

    useEffect(() => {
        dispatch(getAllRole(staffData?.staffId));
    }, []);

    useEffect(() => {
        if (roles) {
            setToggle(roles);
        }
    }, [roles]);

    const handleUpdateAssign = () => {
        if (roles?.id) {
            const formData = new FormData();
            for (let key in toggle) {
                if (toggle[key] !== null) {
                    formData.append(key, toggle[key]);
                }
            }
            dispatch(updatAssignData(formData))
            navigate("/staff")
            dispatch({ type: "CLEAR_STAFF_STATE" })
        } else {
            dispatch(addAssignData(toggle))
            navigate("/staff")
            dispatch({ type: "CLEAR_STAFF_STATE" })
        }
    }

    const handleDashboard = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            dashboard: !toggle?.dashboard
        }));
    };

    const handleUser = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            users: !toggle?.users
        }));
    };

    const handleCorporateUser = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            corporateUsers: !toggle?.corporateUsers
        }));
    };

    const handleProject = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            projects: !toggle?.projects
        }));
    };

    const handleTerms = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            termsCondition: !toggle?.termsCondition
        }));
    };

    const handleCms = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            cms: !toggle?.cms
        }));
    };

    const handleContact = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            contactUs: !toggle?.contactUs
        }));
    };

    // const handleAds = (e) => {
    //     return setToggle((prevState) => ({
    //         ...prevState,
    //         advertisement: !toggle?.advertisement
    //     }));
    // };

    const handlePrivacy = () => {
        return setToggle((prevState) => ({
            ...prevState,
            privacyPolicy: !toggle?.privacyPolicy
        }));
    }

    const handleComet = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            comets: !toggle?.comets
        }));
    };

    const handleCompany = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            corporateCompany: !toggle?.corporateCompany
        }));
    };

    const handleAbout = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            about: !toggle?.about
        }));
    };

    const handleSubscribe = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            subscribedusers: !toggle?.subscribedusers
        }));
    };

    const handleUpcomingProject = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            upcomingProjects: !toggle?.upcomingProjects
        }));
    };

    const handleUpcomingComet = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            upcomingComets: !toggle?.upcomingComets
        }));
    };

    const handleStaff = (e) => {
        return setToggle((prevState) => ({
            ...prevState,
            staff: !toggle?.staff
        }));
    };

    return (
        <>
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="row mb-2 mb-xl-3">
                        <div className="col-auto d-none d-sm-block">
                            <h1 className="h3 mb-3">
                                <strong>Assign Menu</strong> Listing
                            </h1>
                        </div>
                        <div className="col-auto ms-auto text-end mt-n1">
                            <button
                                className="btn btn-primary"
                                data-mode="add"
                                onClick={handleUpdateAssign}
                            >
                                Save & Back
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <table width="100%" className='assign'>
                                <tr >
                                    <td width="80%" className='header'>
                                        <span>Main Caption</span>
                                    </td>
                                    <td width="20%" className='header'>
                                        <span>Is Allowed</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Dashboard</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle.dashboard}
                                                onChange={handleDashboard}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>User</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.users}
                                                onChange={handleUser}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Corporate User</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.corporateUsers}
                                                onChange={handleCorporateUser}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Corporate Company</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.corporateCompany}
                                                onChange={handleCompany}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Project</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.projects}
                                                onChange={handleProject}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Comet</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.comets}
                                                onChange={handleComet}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Staff</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.staff}
                                                onChange={handleStaff}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>About</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.about}
                                                onChange={handleAbout}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Contact Us</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.contactUs}
                                                onChange={handleContact}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Terms And Condition</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.termsCondition}
                                                onChange={handleTerms}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>CMS</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.cms}
                                                onChange={handleCms}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Privacy Policy</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.privacyPolicy}
                                                onChange={handlePrivacy}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Subscribed User</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.subscribedusers}
                                                onChange={handleSubscribe}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Upcoming Projects</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.upcomingProjects}
                                                onChange={handleUpcomingProject}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="80%">
                                        <span className='assign-data'>Upcoming Comets</span>
                                    </td>
                                    <td width="20%">
                                        <label class="switch">
                                            <input type="checkbox"
                                                checked={toggle?.upcomingComets}
                                                onChange={handleUpcomingComet}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AssignStaff