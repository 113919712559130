import React from "react";
import { Modal } from "./Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import moment from "moment";

const ViewContactModal = ({ show, onHide }) => {

  const selectData = useSelector((state) => state?.contact?.selectedContact);

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View Contact Details"
        footer={<Button onClick={onHide}>Cancel</Button>}
      >
        <div className="center">
          <p> Name : {selectData?.name}</p>
          <p> Email : {selectData?.email}</p>
          <p> Subject : {selectData?.subject}</p>
          <p> Message : {selectData?.message}</p>
          <p> Date : {moment(selectData?.inquiryDate).format('DD-MM-YYYY')}</p>
        </div>
        {/* <Button>Cancel</Button> */}
      </Modal>
    </>
  );
};

export default ViewContactModal;
