import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import DeleteModal from "../Modals/DeleteModal";
import {
  selectedPrivacy,
  deletePrivacyData,
  getAllPrivacyData,
} from "../../../redux/actions/privacyPolicy";
import AddPrivacy from "../Modals/PrivacyModal/AddPrivacy";
import ViewPrivacy from "../Modals/PrivacyModal/ViewPrivacy";

const PrivacyPolicy = () => {
  const [addPrivacy, setAddPrivacy] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
  });
  const [searchText, setSearchText] = useState("");
  const [show, setShowModal] = useState(false);
  const [shows, setDeleteModal] = useState(false);
  const [rowUserId, setRowUserId] = useState("");
  const dispatch = useDispatch();

  const PrivacyData = useSelector((state) => state?.privacyPolicy?.privacy);

  useEffect(() => {
    dispatch(getAllPrivacyData(pagination));
  }, [pagination]);

  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(getAllPrivacyData({ ...pagination, search: value }));
  };

  const selectedPrivacyData = (row) => {
    dispatch(selectedPrivacy(row));
    setShowModal(true);
  };

  const addPrivacyData = (row) => {
    setAddPrivacy(true);
    dispatch(selectedPrivacy(row));
  };

  const deleteUserData = () => {
    dispatch(deletePrivacyData(rowUserId, { ...pagination, search: "" }));
    setDeleteModal(false);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: false,
    },
    {
      name: "Privacy Policy",
      selector: (row) => row.policyDetail,
      sortable: false,
      width: "250px",
    },
    {
      name: "Status",
      selector: (row) => (row.isActive === true ? "Active" : "InActive"),
      sortable: false,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdDate).format("DD-MM-YYYY"),
      sortable: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            type="button"
            onClick={() => selectedPrivacyData(row)}
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            className="edit-cls"
            type="button"
            onClick={() => {
              addPrivacyData(row);
            }}
            id={row.userid}
            data-mode="edit"
          >
            <FontAwesomeIcon
              icon={faEdit}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            type="button"
            className="delete-cls"
            id={row.id}
            onClick={() => {
              setDeleteModal(true);
              setRowUserId(row?.id);
            }}
            data-mode="delete"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Privacy Policy</strong> Listing
              </h1>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />{" "}
              <button
                className="btn btn-primary"
                data-mode="add"
                onClick={addPrivacyData}
              >
                Add
              </button>
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={PrivacyData.data}
              onChangeRowsPerPage={(newPageSize) =>
                setPagination({ ...pagination, pageSize: newPageSize })
              }
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(newPage) =>
                setPagination({ ...pagination, pageNumber: newPage })
              }
              pagination
              paginationTotalRows={PrivacyData?.totalRecords}
              paginationServer
              // selectableRows
              responsive
            />
          </div>
        </div>
      </main>
      <AddPrivacy show={addPrivacy} onHide={() => setAddPrivacy(!addPrivacy)} />
      <ViewPrivacy show={show} onHide={() => setShowModal(!show)} />
      <DeleteModal
        deleteUserData={deleteUserData}
        show={shows}
        onHide={() => setDeleteModal(!shows)}
      />
    </>
  );
};

export default PrivacyPolicy;
