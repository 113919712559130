import React from "react";
import { Modal } from "../Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";

const ViewBlogs = ({show, onHide}) => {

  const selectData = useSelector((state) => state?.ourBlogs?.selectedBlog);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: "CLEAR_BLOG_STATE" })
    onHide();
  }

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View Blog Details"
        footer={<Button onClick={handleClose}>Cancel</Button>}
      >
        <div className="center">
          <p> Id : {selectData?.id}</p>
          <p> Title : {selectData?.title}</p>
          <p> Description : {selectData?.description}</p>
          <p> Date : {moment(selectData?.createdDate).format('DD-MM-YYYY')}</p>
          {selectData?.imageUrl && <p> Image : <img src={selectData?.imageUrl} onClick={()=> window.open(selectData?.imageUrl, "_blank")}/></p>}
        </div>
      </Modal>
    </>
  )
}

export default ViewBlogs