import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// import Bootstrap JS
import $ from "jquery";

// import Modal
import { Modal } from "react-bootstrap";

function Header() {
  // popup Active/Inactive
  const [show, setLogoutModal] = useState(false);
  const email = localStorage.getItem("userEmail");

  // used for navigate route
  const history = useNavigate();

  // Active/Inactive Sidebar
  const onSidebarActive = (e) => {
    e.preventDefault();
    $("nav.sidebar.js-sidebar").toggleClass("collapsed");
  };

  // Open Logout Modal
  const logoutModalOpenClose = (e) => {
    setLogoutModal(true);
  };

  // Close Logout Modal
  const logoutModalOpenOpen = (e) => {
    setLogoutModal(false);
  };

  // Logout User
  const logoutUser = (e) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("token");
    history("/");
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light navbar-bg">
        <a
          className="sidebar-toggle js-sidebar-toggle"
          href="/#"
          onClick={onSidebarActive}
        >
          <i className="hamburger align-self-center"></i>
        </a>

        <div className="navbar-collapse collapse">
          <ul className="navbar-nav navbar-align">
            <li className="nav-item dropdown">
              <a
                className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                href="/#"
                data-bs-toggle="dropdown"
              >
                <i className="align-middle" data-feather="settings"></i>
              </a>

              <a
                className="nav-link dropdown-toggle d-none d-sm-inline-block"
                href="/#"
                data-bs-toggle="dropdown"
              >
                <img
                  src="/images/photos/user-profile-pic.png"
                  className="avatar img-fluid rounded me-1"
                  alt="Charles Hall"
                />{" "}
                <span className="text-dark">{email}</span>
              </a>

              <div className="dropdown-menu dropdown-menu-end">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={logoutModalOpenClose}
                >
                  Logout
                </button>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <Modal
        show={show}
        onClick={logoutModalOpenOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="sign-in-title"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">Are you sure you want to logout ?</p>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-danger" onClick={logoutUser}>
            Logout
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={logoutModalOpenOpen}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Header;
