import { addAssign, getAllRoleData, getRoleData } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestRoleData = () => {
  return {
    type: type.ROLE_RIGHTS_REQUEST,
  };
};

const successRole = (role) => {
  return {
    type: type.ROLE_RIGHTS_SUCCESS,
    payload: role,
  };
};

const successAllRole = (role) => {
    return {
      type: type.ROLE_RIGHTS_ALL_SUCCESS,
      payload: role,
    };
  };

const errorRole = (error) => {
  return {
    type: type.ROLE_RIGHTS_FAILURE,
    message: error,
  };
};

export const selectedRole = (role) => {
  return {
    type: type.SELECTED_ROLE_RIGHTS_SUCCESS,
    payload: role
  };
};

export const selectedAllRole = (role) => {
  return {
    type: type.SELECTED_ALL_ROLE_RIGHTS_SUCCESS,
    payload: role
  };
};

export const getAllRole = (staffId) => {
  return function (dispatch) {
    dispatch(requestRoleData());
    getRoleData(staffId)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successRole(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorRole(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(error);
      });
  };
};

export const getAllRoleDetails = () => {
    return function (dispatch) {
      dispatch(requestRoleData());
      getAllRoleData()
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(successAllRole(resp.data));
          } else {
            const error = "Not data found";
            dispatch(errorRole(error));
          }
        })
        .catch((errorMasg) => {
          const error = "API is not working";
          console.log(error);
        });
    };
  };

// export const updatStaffData = (body) => {
//   return (dispatch) => {
//     return updateStaff(body)
//       .then((res) => {
//         toast.success("Staff Updated Successfully");
//         dispatch(getAllStaff())
//       })
//       .catch((err) => {});
//   };
// };

// export const deleteStaffData = (userDelete) => {
//   return (dispatch) => {
//     dispatch({
//       type: type.DELETE_STAFF_REQUEST,
//     });
//     return deleteStaff(userDelete)
//       .then((res) => {
//         dispatch({
//           type: type.DELETE_STAFF_SUCCESS,
//           payload: res,
//           data: userDelete,
//         });
//         dispatch(getAllStaff());
//       })
//       .catch((err) => {
//         dispatch({
//           type: type.DELETE_STAFF_FAILURE,
//         });
//       });
//   };
// };
