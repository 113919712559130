import {
  deleteData,
  deleteSubscribtion,
  getSubscriptionAllData,
  getSubscriptionByUser,
  updateSubscribeData,
} from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestSubscriptionData = () => {
  return {
    type: type.SUBSCRIPTION_REQUEST,
  };
};

const successSubscription = (subscription) => {
  return {
    type: type.SUBSCRIPTION_SUCCESS,
    payload: subscription,
  };
};

const errorSubscription = (error) => {
  return {
    type: type.SUBSCRIPTION_FAILURE,
    message: error,
  };
};

const requestSubscriptionUser = () => {
  return {
    type: type.SUBSCRIPTION_USER_REQUEST,
  };
};

const successSubscriptionUser = (subscription) => {
  return {
    type: type.SUBSCRIPTION_USER_SUCCESS,
    payload: subscription,
  };
};

const errorSubscriptionUser = (error) => {
  return {
    type: type.SUBSCRIPTION_USER_FAILURE,
    message: error,
  };
};

export const selectedSubscription = (data) => {
  return {
    type: type.SELECTED_SUBSCRIPTION_SUCCESS,
    payload: data,
  };
};

const updateSubscription = (data) => ({
  type: type.UPDATE_SUBSCRIPTION_STATUS,
  data,
});

export const getAllSubscriptionData = (pageObj) => {
  return function (dispatch) {
    dispatch(requestSubscriptionData());
    getSubscriptionAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successSubscription(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorSubscription(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(error);
      });
  };
};

export const getAllSubscriptionUser = (id) => {
  return function (dispatch) {
    dispatch(requestSubscriptionUser());
    getSubscriptionByUser(id)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successSubscriptionUser(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorSubscriptionUser(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(error);
      });
  };
};

export const deleteSubscribeUser = (userDelete, pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_SUBSCRIBED_USER_REQUEST,
    });
    return deleteSubscribtion(userDelete)
      .then((res) => {
        toast.success("Subscribed User Deleted Successfully");
        dispatch({
          type: type.DELETE_SUBSCRIBED_USER_SUCCESS,
          payload: res,
          data: userDelete,
        });
        dispatch(getAllSubscriptionData(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_SUBSCRIBED_USER_FAILURE,
        });
      });
  };
};

export const editSubscribeData = (body) => {
  return (dispatch) => {
    return updateSubscribeData(body)
      .then((res) => {
        toast.success("Subscribed Successfully");
        dispatch(updateSubscription(res.data));
        dispatch(
          getAllSubscriptionData({ pageNumber: 1, pageSize: 10, search: "" })
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};
