import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import ExportExcel from "../../Utils/ExportExcel";
import axios from "axios";
import {
  getAllContactData,
  selectedContact,
} from "../../../redux/actions/contact";
import ViewContactModal from "../Modals/ViewContactModal";

const Contact = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const AllContact = useSelector((state) => state?.contact?.contact);

  useEffect(() => {
    dispatch(
      getAllContactData({
        pageSize: pageSize,
        pageNumber: page,
        startdate: "",
        enddate: "",
        search: "",
      })
    );
    (async () => {
      const user = await axios.get(
        "https://apiyouni.rlogical.com/api/ContactUs/GetContactInfo"
      );
      setData(user?.data?.data);
    })();
  }, [page, pageSize]);

  const selectedContactData = (row) => {
    dispatch(selectedContact(row));
    setShowModal(true);
  };

  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(
      getAllContactData({
        pageSize: pageSize,
        pageNumber: page,
        startdate: "",
        enddate: "",
        search: value,
      })
    );
  };

  const handleDateSearch = (event) => {
    if (startDate && endDate !== "") {
      dispatch(
        getAllContactData({
          pageSize: pageSize,
          pageNumber: page,
          startdate: moment(startDate).format("YYYY-MM-DD"),
          enddate: moment(endDate).format("YYYY-MM-DD"),
          search: "",
        })
      );
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
      width: "250px",
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: false,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: false,
      width: "250px",
    },
    {
      name: "Date",
      selector: (row) => moment(row.inquiryDate).format("DD-MM-YYYY"),
      sortable: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            type="button"
            onClick={() => selectedContactData(row)}
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
  ];
  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Contact</strong> Listing
              </h1>
            </div>
            {/* <div className="col-auto d-none d-sm-block">
                  <p>Start Date :<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /></p>
                  <p>End Date :<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} /></p>
                  <button onClick={handleDateSearch}>Search</button>
                  </div> */}
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />
              <ExportExcel data={data} fileName="Contact" />
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={AllContact.data}
              onChangeRowsPerPage={(newPageSize) => setPageSize(newPageSize)}
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(newPage) => setPage(newPage)}
              pagination
              paginationTotalRows={AllContact?.totalRecords}
              paginationServer
              //expandableRows
              //expandableRowsComponent={ExpandedComponent}
              // selectableRows
              responsive
            />
          </div>
        </div>
      </main>
      <ViewContactModal show={show} onHide={() => setShowModal(!show)} />
    </>
  );
};

export default Contact;
