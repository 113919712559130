import React from "react";
//import { Link, useNavigate } from "react-router-dom";

// import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// import Bootstrap JS
//import $ from 'jquery';

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row text-muted">
            <div className="col-6 text-start">
              <p className="mb-0">
                &copy; Copyright {new Date().getFullYear()}&nbsp;
                <a
                  className="text-muted"
                  href="https://youni-frontend.web.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>Youni</strong>
                </a>{" "}
                All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
