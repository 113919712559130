import { addBlogs, deleteBlogs, getBlogsAllData, updateBlogs } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestBlogData = () => {
  return {
    type: type.BLOGS_REQUEST,
  };
};

const successBlog = (blog) => {
  return {
    type: type.BLOGS_SUCCESS,
    payload: blog,
  };
};

const errorBlog = (error) => {
  return {
    type: type.BLOGS_FAILURE,
    message: error,
  };
};

export const selectedBlog = (data) => {
  return {
    type: type.SELECTED_BLOGS_SUCCESS,
    payload: data
  };
};

export const getAllBlogData = (pageObj) => {
  return function (dispatch) {
    dispatch(requestBlogData());
    getBlogsAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successBlog(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorBlog(error));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const addBlogData = (body) => {
  return (dispatch) => {
    return addBlogs(body)
      .then((res) => {
        toast.success("Blog Added Successfully");
        dispatch(getAllBlogData({ pageNumber: 1, pageSize: 10, search: "" }))
   
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const updateBlogData = (body) => {
  return (dispatch) => {
    return updateBlogs(body)
      .then((res) => {
        toast.success("Blog Updated Successfully");
        dispatch(getAllBlogData({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const deleteBlogData = (userDelete,pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_BLOGS_REQUEST,
    });
    return deleteBlogs(userDelete)
      .then((res) => {
        dispatch({
          type: type.DELETE_BLOGS_SUCCESS,
          payload: res,
          data: userDelete,
        });
        toast.success("Blog Deleted Successfully");
        dispatch(getAllBlogData(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_BLOGS_FAILURE,
        });
      });
  };
};

