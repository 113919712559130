import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//import form validation check
import isEmpty from "../../Validation/is-empty";
import _isEmpty from 'lodash/isEmpty';
// import redux function
import { useDispatch, useSelector } from "react-redux";
import { loginAdmin } from "../../../redux/actions/userLogin";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPaw, setShowPaw] = useState(false);
  const [emailErr, setEmailErr] = useState({});
  const [passwordErr, setPasswordErr] = useState({});
  const [login, checkLogin] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const isLogin = useSelector((state) => state.data.isLogin);
  //const errorMessage = useSelector((state) => state.data.error);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (login) {
      if (isLogin) {
        navigate("/dashboard");
      }
    }
  }, [login, isLogin, navigate]);

  // submit Login form
  const onSubmitLogin = (e) => {
    e.preventDefault();
    const isValid = formValidation();

    if (isValid) {
      const userLoginDetails = {
        emailId: email,
        password: password,
      };

      dispatch(loginAdmin(userLoginDetails))
      setTimeout(() => {
        if (!_isEmpty(localStorage.getItem('accessToken'))) {
          navigate("/dashboard");
        }
      }, 300)

      checkLogin(true);
    }
  };

  // used to form validation
  const formValidation = () => {
    const emailErr = {};
    const passwordErr = {};

    let isValid = true;

    if (isEmpty(email)) {
      emailErr.message = "Please enter email.";
      isValid = false;
    } else {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        emailErr.message = "Please enter valid email.";
        isValid = false;
      }
    }

    if (isEmpty(password)) {
      passwordErr.message = "Please enter password.";
      isValid = false;
    }

    setEmailErr(emailErr);
    setPasswordErr(passwordErr);
    return isValid;
  };

  const handleClickShowPassword = () => {
    setShowPaw(!showPaw);
    if (type === "password") {
      setType("text");
      setIcon(eye);
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  return (
    <>
      <main
        className="d-flex w-100 loginPageContainer"
      // style={{ backgroundImage: url("/images/photos/bg.jpg") }}
      >
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle loginPageCard">
                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      <div className="text-center">
                        <img
                          src="/images/photos/user-profile-pic.png"
                          alt="Login"
                          className="img-fluid rounded-circle"
                          width="132"
                          height="132"
                        />
                      </div>
                      <form onSubmit={onSubmitLogin}>
                        <div className="mb-4">
                          <label className="form-label login-form-label">
                            Email
                          </label>
                          <input
                            className="form-control form-control-lg"
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            autoComplete="off"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />

                          {Object.keys(emailErr).map((key) => {
                            return (
                              <div key={key} style={{ color: "red" }}>
                                {emailErr[key]}
                              </div>
                            );
                          })}
                        </div>
                        <div className="mb-4">
                          <label className="form-label login-form-label">
                            Password
                          </label>
                          <input
                            className="form-control form-control-lg"
                            type={showPaw ? 'text' : 'password'}
                            name="password"
                            placeholder="Enter your password"
                            autoComplete="off"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                          <span className="pass-show-login" onClick={handleClickShowPassword}>
                            <Icon icon={icon} />
                          </span>
                          {Object.keys(passwordErr).map((key) => {
                            return (
                              <div key={key} style={{ color: "red" }}>
                                {passwordErr[key]}
                              </div>
                            );
                          })}
                        </div>
                        <div className="text-center mt-5">
                          <button
                            type="submit"
                            className="btn btn-lg common-btn gradient-btn"
                            onClick={onSubmitLogin}
                          >
                            Login
                          </button>
                          &nbsp; &nbsp;
                        </div>
                      </form>
                      <p id="form_responce" className="form-responce mt-5"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Login;
