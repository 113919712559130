import React from "react";
import { Modal } from "./Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

const ViewProject = ({ show, onHide }) => {

  const selectData = useSelector((state) => state?.projects?.selectedProject);

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View Project Details"
        footer={<Button onClick={onHide}>Cancel</Button>}
      >
        <div className="center">
          <p> Project Id : {selectData?.projectId}</p>
          <p> Project Name : {selectData?.projectName}</p>
          <p> Project Note : {selectData?.note}</p>
          <p> Description : {selectData?.description}</p>
          {/* <p> Address : {selectData?.macaddress}</p> */}
        </div>
      </Modal>
    </>
  );
};

export default ViewProject;
