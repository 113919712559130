import { addApplicationPage, deleteApplicationPage, getPgaesAllData, updateApplicationPage } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestApplicationPageData = () => {
  return {
    type: type.APPLICATION_PAGE_REQUEST,
  };
};

const successApplicationPage = (pages) => {
  return {
    type: type.APPLICATION_PAGE_SUCCESS,
    payload: pages,
  };
};

const errorApplicationPage = (error) => {
  return {
    type: type.APPLICATION_PAGE_FAILURE,
    message: error,
  };
};

export const selectedApplicationPage = (data) => {
  return {
    type: type.SELECTED_APPLICATION_PAGE_SUCCESS,
    payload: data
  };
};

export const getAllApplicationPagesData = (pageObj) => {
  return function (dispatch) {
    dispatch(requestApplicationPageData());
    getPgaesAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successApplicationPage(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorApplicationPage(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(error);
      });
  };
};

export const addApplicationPageData = (body) => {
  return (dispatch) => {
    return addApplicationPage(body)
      .then((res) => {
        toast.success("Application Page Added Successfully");
        dispatch(getAllApplicationPagesData({ pageNumber: 1, pageSize: 10, search: "" }))
   
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const updatApplicationPageData = (body) => {
  return (dispatch) => {
    return updateApplicationPage(body)
      .then((res) => {
        toast.success("Application Page Updated Successfully");
        dispatch(getAllApplicationPagesData({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const deleteApplicationPageData = (userDelete,pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_APPLICATION_PAGE_REQUEST,
    });
    return deleteApplicationPage(userDelete)
      .then((res) => {
        dispatch({
          type: type.DELETE_APPLICATION_PAGE_SUCCESS,
          payload: res,
          data: userDelete,
        });
        toast.success("Application Page Deleted Successfully");
        dispatch(getAllApplicationPagesData(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_APPLICATION_PAGE_FAILURE,
        });
      });
  };
};

