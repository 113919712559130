import * as type from "../type";

const initialState = {
  comets: [],
  loading: false,
  error: "",
  selectedComet:null,
  upcomingComet: [],
};

const comets = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_COMET_SUCCESS:
      return{
        ...state,
        selectedComet: action?.payload
      }
    case type.COMETS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.COMETS_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        comets: action.payload,
      };

    case type.COMETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

      case type.UPCOMING_COMETS_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case type.UPCOMING_COMETS_SUCCESS:
        return {
          ...state,
          loading: false,
          isLogin: true,
          upcomingComet: action.payload,
        };
  
      case type.UPCOMING_COMETS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.message,
        };

      case type.DELETE_COMETS_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case type.DELETE_COMETS_SUCCESS: {
        return {
          ...state,
          comets: (state?.comets?.data).filter((singleUser) => {
            return singleUser?.cometId !== action?.payload?.data
          }
          ),
          loading: true,
          error: "",
        };
        
      }
      case type.DELETE_COMETS_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }


    default:
      return state;
  }
};

export default comets;
