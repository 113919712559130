import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import {
  deleteSubscribeUser,
  editSubscribeData,
  getAllSubscriptionData,
  getAllSubscriptionUser,
  selectedSubscription,
} from "../../../redux/actions/subscription";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewSubscription from "../Modals/Subscription/ViewSubscription";
import moment from "moment";
import DeleteModal from "../Modals/DeleteModal";
import { deleteUser } from "../../../redux/actions/getUsers";
import DeleteSubscribeModal from "../Modals/DeleteSubscribeModal";
import { Badge, Button } from "react-bootstrap";

const Subscription = () => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
  });
  const [searchText, setSearchText] = useState("");
  const [show, setShowModal] = useState(false);
  const [shows, setDeleteModal] = useState(false);
  const [rowUserId, setRowUserId] = useState("");
  const [rowStripeId, setRowStripeId] = useState("");
  const dispatch = useDispatch();

  const SubscriptionData = useSelector(
    (state) => state?.subscription?.subscription
  );

  useEffect(() => {
    dispatch(getAllSubscriptionData(pagination));
  }, [pagination]);

  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(getAllSubscriptionData({ ...pagination, search: value }));
  };

  const selectedUserSubscription = (row) => {
    dispatch(getAllSubscriptionUser(row?.userid));
    dispatch(selectedSubscription(row));
    setShowModal(true);
  };

  // Used to Delete User
  const deleteUserData = () => {
    dispatch(deleteUser(rowUserId, { ...pagination, search: "" }));
    setDeleteModal(false);
  };

  const deleteSubscribe = () => {
    dispatch(deleteSubscribeUser(rowStripeId, { ...pagination, search: "" }));
    setDeleteModal(false);
  };

  const updateSubscriptionStatus = (row) => {
    const updatedRow = { ...row, status: "success" };
    console.log(updatedRow);
    // dispatch(editSubscribeData(updatedRow));
  };
  const columns = [
    // {
    //     name: "User Id",
    //     selector: (row) => row.userid,
    //     sortable: false,
    // },
    {
      name: "User Name",
      selector: (row) => row.username,
      sortable: false,
    },
    {
      name: "Email Id",
      selector: (row) => row.emailId,
      sortable: false,
    },
    {
      name: "User Type",
      selector: (row) => (row.corporateId ? "Corporate User" : "Normal User"),
      sortable: false,
    },
    {
      name: "Plan Type",
      selector: (row) => row.subscriptionType,
      sortable: false,
    },
    {
      name: "Subscription Date",
      selector: (row) => moment(row.startDate).format("DD-MM-YYYY"),
      sortable: false,
    },
    {
      name: "Payment Status",
      selector: (row) => (
        <>
          {/* {row.status === "failed" ? ( */}
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => {
              updateSubscriptionStatus(row);
            }}
          >
            Subscribe
          </Button>
          {/* ) : (
            <Badge text="success" pill bg="light">
              Subscribed
            </Badge>
          )} */}
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            onClick={() => selectedUserSubscription(row)}
            type="button"
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            type="button"
            className="delete-cls"
            id={row.userid}
            onClick={() => {
              setDeleteModal(true);
              setRowUserId(row.userid);
              setRowStripeId(row?.stripeId);
            }}
            data-mode="delete"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Subscriptions</strong> Listing
              </h1>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={SubscriptionData.data}
              onChangeRowsPerPage={(newPageSize) =>
                setPagination({ ...pagination, pageSize: newPageSize })
              }
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              onChangePage={(newPage) =>
                setPagination({ ...pagination, pageNumber: newPage })
              }
              pagination
              paginationTotalRows={SubscriptionData?.totalRecords}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              paginationServer
              // selectableRows
              responsive
            />
          </div>
        </div>
      </main>
      <ViewSubscription show={show} onHide={() => setShowModal(!show)} />
      <DeleteSubscribeModal
        show={shows}
        deleteUserData={deleteUserData}
        deleteSubscribtion={deleteSubscribe}
        onHide={() => setDeleteModal(!shows)}
      />
    </>
  );
};

export default Subscription;
