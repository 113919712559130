import * as type from "../type";

const initialState = {
  pages: [],
  loading: false,
  error: "",
  selectedPages: null,
};

const applicationPage = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_APPLICATION_PAGE_SUCCESS:
      return {
        ...state,
        selectedPages: action?.payload
      }
    case type.CLEAR_APPLICATION_STATE:
      return {
        ...state,
        selectedPages: []
      };
    case type.APPLICATION_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.APPLICATION_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        pages: action.payload,
      };

    case type.APPLICATION_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.DELETE_APPLICATION_PAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case type.DELETE_APPLICATION_PAGE_SUCCESS: {
      return {
        ...state,
        pages: (state?.pages?.data).filter((singleUser) => {
          return singleUser?.id !== action?.payload?.data
        }
        ),
        loading: true,
        error: "",
      };

    }
    case type.DELETE_APPLICATION_PAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default applicationPage;
