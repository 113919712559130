import React, { useState, useEffect } from 'react'
import { Modal } from '../Modal'
import Button from 'react-bootstrap/Button';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from 'react-redux';
import "./AddTerms.css"
import { addTermsData, updatTermsData } from '../../../../redux/actions/termsAndCondition';
import isEmpty from '../../validation/isEmpty';

const AddTerms = ({ show, onHide }) => {

    const [termsErr, setTermsErr] = useState('')
    const initialValues = {
        termsDetail: "",
        isActive: false,
    };

    const [state, setState] = useState({ ...initialValues })
    const [isActive, setIsActive] = useState(false);
    const dispatch = useDispatch();

    const selectData = useSelector((state) => state?.termsAndCondition?.selectedTerms);

    useEffect(() => {
        setState(selectData);
    }, [selectData]);

    const handleChange = (e) => {

        if (e.target.name === "termsDetail") {
            setTermsErr("");
        }

        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const validateForm = () => {
        let isValid = true;

        if (isEmpty(state?.termsDetail)) {
            setTermsErr("Please enter description");
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = validateForm();
        if (isValid && !selectData?.id) {
            const data = {
                TermsDetail: state?.termsDetail,
                isActive: state?.isActive ? state?.isActive : false,
                createdBy: 0,
            }
            dispatch(addTermsData(data))
            handleClose();
        } else if (isValid) {
            const data = {
                Id: state?.id,
                TermsDetail: state?.termsDetail,
                IsActive: state?.isActive,
            }
            const formData = new FormData();
            for (let key in data) {
                if (data[key] !== null) {
                    formData.append(key, data[key]);
                }
            }
            dispatch(updatTermsData(formData))
            handleClose()
        }

    }

    const handleClose = () => {
        dispatch({ type: "CLEAR_TERMS_STATE" })
        setTermsErr("");
        onHide();
    }

    const handleActive = (event) => {
        if (event.target.checked) {
            setState({ ...state, ["isActive"]: true });
        } else {
            setState({ ...state, ["isActive"]: false });
        }
    }


    return (
        <>
            <Modal
                show={show}
                className="modal fade theme_modal follow"
                onHide={onHide}
                title={state?.id ? "Update Terms and Condition Details" : "Add Terms and Condition Details"}
                footer={
                    <>
                        <Button onClick={handleSubmit} >{state?.id ? "Update" : "Add"}</Button>
                        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    </>}
            >
                <form id="userForm" method="put" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="form-label">Terms And Condition</label>
                        <textarea
                            className="form-control form-control-lg"
                            type="text"
                            value={state?.termsDetail}
                            name="termsDetail"
                            onChange={handleChange}
                        />
                         {termsErr && <span className="error-msg">{termsErr}</span>}
                    </div>

                    <div className="mb-4">
                        <label className="form-label">Active </label>{" "}
                        <label class="switch">
                            <input type="checkbox"
                                checked={
                                    state?.isActive
                                        ? state?.isActive
                                        : false
                                }
                                onChange={handleActive} />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddTerms