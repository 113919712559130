import * as type from "../type";

const initialState = {
  users: [],
  corporateUser: [],
  userCount: [],
  corporateUserCount: [],
  loading: false,
  error: "",
  selectedUser: null,
};

const getUsers = (state = initialState, action) => {
  switch (action.type) {

    case type.SELECTED_USER_SUCCESS:
    return{
      ...state,
      selectedUser: action?.payload
    }
    case type.CLEAR_USERS_STATE:
      return{
        ...state,
        selectedUser: []
      }
    case type.GET_USERS_REQUEST:
    case type.GET_CORPORATE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        isLogin: false,
      };

    case type.GET_USERS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        userCount: action.payload,
      };
    case type.GET_CORPORATE_USERS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        corporateUserCount: action.payload,
      };
    case type.GET_CORPORATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        corporateUser: action.payload,
      };
    case type.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        users: action.payload,
      };

    case type.GET_USERS_FAILURE:
    case type.GET_CORPORATE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        isLogin: false,
        error: action.message,
      };

      case type.DELETE_USER_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case type.DELETE_USER_SUCCESS: {
        return {
          ...state,
          users: (state?.users?.data).filter((singleUser) => {
            return singleUser?.userid !== action?.payload?.data
          }
          ),
          loading: true,
          error: "",
        };
        
      }
      case type.DELETE_USER_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

      case type.DELETE_CORPORATE_USERS_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case type.DELETE_CORPORATE_USERS_SUCCESS: {
        return {
          ...state,
          corporateUser: (state?.corporateUser?.data).filter((singleUser) => {
            return singleUser?.userid !== action?.payload?.data
          }
          ),
          loading: true,
          error: "",
        };
        
      }
      case type.DELETE_CORPORATE_USERS_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }

    default:
      return state;
  }
};

export default getUsers;
