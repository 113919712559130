import * as type from "../type";

const initialState = {
  staff: [],
  loading: false,
  error: "",
  selectedStaff: null,
};

const staff = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_STAFF_SUCCESS:
      return {
        ...state,
        selectedStaff: action?.payload
      }
    case type.CLEAR_STAFF_STATE:
      return {
        ...state,
        selectedStaff: []
      }
    case type.STAFF_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        staff: action.payload,
      };

    case type.STAFF_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    case type.DELETE_STAFF_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case type.DELETE_STAFF_SUCCESS: {
      return {
        ...state,
        staff: (state?.staff?.data).filter((singleUser) => {
          return singleUser?.staffId !== action?.payload?.data
        }
        ),
        loading: true,
        error: "",
      };

    }
    case type.DELETE_STAFF_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }


    default:
      return state;
  }
};

export default staff;
