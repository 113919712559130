import { combineReducers } from "redux";
import getUsers from "./getUsers";
import loginAdmin from "./userLogin";
import corporateCompany from "./corporateCompany";
import projects from "./projects";
import planets from "./planets";
import moons from "./moons";
import satellite from "./satellite";
import comets from './comets'
import contact from "./contact";
import aboutUs from "./aboutUs";
import termsAndCondition from "./termsAndCondition";
import privacyPolicy from "./privacyPolicy"
import staff from "./staff";
import roleRights from "./roleRights";
import applicationPage from "./applicationPage";
import quotes from "./quotes";
import ourBlogs from "./ourBlogs";
import ourCommunity from "./ourCommunity";
import subscription from "./subscription";

const rootReducers = combineReducers({
  data: loginAdmin,
  users: getUsers,
  corporateCompany: corporateCompany,
  projects: projects,
  planets: planets,
  moons: moons,
  satellite: satellite,
  comets: comets,
  contact : contact,
  aboutUs : aboutUs,
  termsAndCondition : termsAndCondition,
  privacyPolicy: privacyPolicy,
  staff : staff,
  roleRights: roleRights,
  applicationPage: applicationPage,
  quotes : quotes,
  ourBlogs : ourBlogs,
  ourCommunity : ourCommunity,
  subscription : subscription,
});

export default rootReducers;
