import { getStarsAllData, deleteProject, getUpcomingStarsAllData } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestStarsData = () => {
  return {
    type: type.PROJECTS_REQUEST,
  };
};

const successStars = (stars) => {
  return {
    type: type.PROJECTS_SUCCESS,
    payload: stars,
  };
};

const errorStars = (error) => {
  return {
    type: type.PROJECTS_FAILURE,
    message: error,
  };
};

export const selectedProject = (project) => {
  return {
    type: type.SELECTED_PROJECT_SUCCESS,
    payload: project
  };
};

export const getAllProjects = (pageObj) => {
  return (dispatch) => {
    dispatch(requestStarsData());  
      return getStarsAllData(pageObj)
      .then((resp) => {
        if (  resp.status === 200) {
          dispatch(successStars(resp?.data));
        } else {
          const error = "Not data found";
          dispatch(errorStars(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(errorMasg);
      });
  };
};

export const getAllUpcomingProjects = (pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.UPCOMING_PROJECTS_REQUEST,
    });
    return getUpcomingStarsAllData(pageObj)
      .then((res) => {
        dispatch({
          type: type.UPCOMING_PROJECTS_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: type.UPCOMING_PROJECTS_FAILURE,
        });
        toast.error(err?.response?.data?.message);
      });
  };
};

export const deleteProjectData = (userDelete,pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_PROJECT_REQUEST,
    });
    return deleteProject(userDelete)
      .then((res) => {
        dispatch({
          type: type.DELETE_PROJECT_SUCCESS,
          payload: res,
          data: userDelete,
        });
        dispatch(getAllProjects(pageObj));
        toast.success("Project Deleted Successfully");
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_PROJECT_FAILURE,
        });
        toast.error(err?.response?.data?.message);
      });
  };
};
