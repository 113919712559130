import { getCometsAllData, deleteComet, getUpcomingCometsAllData } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestCometsData = () => {
  return {
    type: type.COMETS_REQUEST,
  };
};

const successComets = (comets) => {
  return {
    type: type.COMETS_SUCCESS,
    payload: comets,
  };
};

const errorComets = (error) => {
  return {
    type: type.COMETS_FAILURE,
    message: error,
  };
};

export const selectedComet = (comet) => {
  return {
    type: type.SELECTED_COMET_SUCCESS,
    payload: comet
  };
};

export const getAllComets = (pageObj) => {
  return function (dispatch) {
    dispatch(requestCometsData());
    getCometsAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successComets(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorComets(error));
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const getAllUpcomingComet = (pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.UPCOMING_COMETS_REQUEST,
    });
    return getUpcomingCometsAllData(pageObj)
      .then((res) => {
        dispatch({
          type: type.UPCOMING_COMETS_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: type.UPCOMING_COMETS_FAILURE,
        });
        toast.error(err?.response?.data?.message);
      });
  };
};

export const deleteCometData = (userDelete,pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_COMETS_REQUEST,
    });
    return deleteComet(userDelete)
      .then((res) => {
        toast.success("Comet Deleted Successfully");
        // dispatch({
        //   type: type.DELETE_COMETS_SUCCESS,
        //   payload: res,
        //   data: userDelete,
        // });
        dispatch(getAllComets(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_COMETS_FAILURE,
        });
      });
  };
};
