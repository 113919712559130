import { getContactAllData } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestContactData = () => {
  return {
    type: type.CONTACT_US_REQUEST,
  };
};

const successContact = (Contact) => {
  return {
    type: type.CONTACT_US_SUCCESS,
    payload: Contact,
  };
};

const errorContact = (error) => {
  return {
    type: type.CONTACT_US_FAILURE,
    message: error,
  };
};

export const selectedContact = (data) => {
  return {
    type: type.SELECTED_CONTACT_SUCCESS,
    payload: data
  };
};

export const getAllContactData = (pageObj) => {
  return function (dispatch) {
    dispatch(requestContactData());
    getContactAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successContact(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorContact(error));
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};
