import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../Layouts/Sidebar";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";

const PrivateRoute = () => {
  //const auth = null; // determine if authorized, from context or however you're doing it
  const auth = localStorage.getItem("accessToken");
// determine if authorized, from context or however you're doing it
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ?
    <div className="wrapper">
      <Sidebar />
      <div className="main">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </div> : <Navigate to="/" />;
};

export default PrivateRoute;
