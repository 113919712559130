import * as type from "../type";

const initialState = {
  satellites: [],
  loading: false,
  error: "",
};

const satellite = (state = initialState, action) => {
  switch (action.type) {
    case type.SATELLITE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.SATELLITE_SUCCESS:
      return {
        ...state,
        loading: false,
        satellites: action.payload,
      };

    case type.SATELLITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    default:
      return state;
  }
};

export default satellite;
