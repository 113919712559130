import React, { useState, useEffect } from 'react'
import { Modal } from '../Modal'
import Button from 'react-bootstrap/Button';
import isEmpty from '../../validation/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../../../../firebase-config';
import { addBlogData, updateBlogData } from '../../../../redux/actions/ourBlogs';
import { toast } from "react-toastify";

const AddBlogs = ({ show, onHide }) => {

  const [descriptionErr, setDescriptionErr] = useState('')
  const [titleErr, setTitleErr] = useState('')
  const initialValues = {
    imageUrl: null,
    title: "",
    description: "",
    isActive: false,
  };

  const [state, setState] = useState({ ...initialValues })
  const [image, setImage] = useState(null)
  const [isActive, setIsActive] = useState(false);
  const [percent, setPercent] = useState(0)
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const selectData = useSelector((state) => state?.ourBlogs?.selectedBlog);

  useEffect(() => {
    setState(selectData);
  }, [selectData]);

  const handleChange = (e) => {

    if (e.target.name === "description") {
      setDescriptionErr("");
    }
    if (e.target.name === "title") {
      setTitleErr("");
    }

    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;

    if (isEmpty(state?.description)) {
      setDescriptionErr("Please enter description");
      isValid = false;
    }
    if (isEmpty(state?.title)) {
      setTitleErr("Please enter title");
      isValid = false;
    }


    return isValid;
  };

  const handleProfileChange = (e) => {
    setLoader(true);
    const file = e.target.files[0];
    if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
      toast.error("This file format is not supported");
      setLoader(false);
    } else {
      setImage(file);
      file["id"] = Math.random();
      const promises = [];
      const storageRef = ref(storage, `/blogs/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            let URLS = url;
            if (URLS) {
              setImage(URLS);
              setLoader(false);
            }
            setState({ ...state, ["imageUrl"]: URLS });
          });
        }
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid && !selectData?.id) {
      const data = {
        title: state?.title,
        description: state?.description,
        imageUrl: state?.imageUrl,
        isActive: state?.isActive ? state?.isActive : false,
        createdBy: 0,
      }
      dispatch(addBlogData(data))
      handleClose()
    } else if (isValid) {
      const data = {
        Id: state?.id,
        Title: state?.title,
        Description: state?.description,
        ImageUrl: state?.imageUrl,
        IsActive: state?.isActive,
        UpdatedBy: 1,
      }
      const formData = new FormData();
      for (let key in data) {
        if (data[key] !== null) {
          formData.append(key, data[key]);
        }
      }
      dispatch(updateBlogData(formData))
      setImage('')
      handleClose()
    }

  }

  const handleClose = () => {
    dispatch({ type: "CLEAR_BLOG_STATE" })
    setDescriptionErr("")
    setTitleErr("")
    onHide();
  }

  const handleActive = (event) => {
    if (event.target.checked) {
      setState({ ...state, ["isActive"]: true });
    } else {
      setState({ ...state, ["isActive"]: false });
    }
  }

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow common-popup-wrapper"
        onHide={onHide}
        title={state?.id ? "Update Blog" : "Add Blog"}
        footer={
          <>
            <Button onClick={handleSubmit}>{state?.id ? "Update" : "Add"}</Button>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          </>}
      >
        <div className='fit-image'>
          <img src={state?.imageUrl} />
          <div className="input_file">
            <input
              className="edit-file"
              type="file"
              id="file"
              accept="image/*"
              name="imageUrl"
              onChange={handleProfileChange}
            />
              Upload Image
          </div>
          {loader && (
            <>
              {" "}
              <div className="cssloader_main">
                {" "}
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>{" "}
                </div>{" "}
              </div>
            </>
          )}
        </div>
        <div className="mb-4">
          <label className="form-label">Title</label>
          <input
            className='form-control form-control-lg'
            name="title"
            placeholder='Please enter title'
            value={state?.title}
            onChange={handleChange}
          />
          {titleErr && <span className="error-msg">{titleErr}</span>}
        </div>
        <div className="mb-4">
          <label className="form-label">Description </label>
          <textarea
            className='form-control form-control-lg'
            name="description" rows="4" cols="93"
            placeholder='Please enter description'
            value={state?.description}
            onChange={handleChange}
          />
          {descriptionErr && <span className="error-msg">{descriptionErr}</span>}
        </div>
        <div className="mb-4">
          <label className="form-label">Active </label>{" "}
          <label class="switch">
            <input type="checkbox"
              checked={
                state?.isActive
                  ? state?.isActive
                  : false
              }
              onChange={handleActive} />
            <span class="slider round"></span>
          </label>
        </div>
      </Modal>
    </>
  )
}

export default AddBlogs