import { addAboutUs, deleteAboutUs, getAboutAllData, updateAboutUs } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestAboutData = () => {
  return {
    type: type.ABOUT_US_REQUEST,
  };
};

const successAbout = (about) => {
  return {
    type: type.ABOUT_US_SUCCESS,
    payload: about,
  };
};

const errorAbout = (error) => {
  return {
    type: type.ABOUT_US_FAILURE,
    message: error,
  };
};

export const selectedAbout = (data) => {
  return {
    type: type.SELECTED_ABOUT_SUCCESS,
    payload: data
  };
};

export const getAllAboutUsData = (pageObj) => {
  return function (dispatch) {
    dispatch(requestAboutData());
    getAboutAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successAbout(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorAbout(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(error);
      });
  };
};

export const addAboutData = (body) => {
  return (dispatch) => {
    return addAboutUs(body)
      .then((res) => {
        toast.success("About Us Added Successfully");
        dispatch(getAllAboutUsData({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const updatAboutData = (body) => {
  return (dispatch) => {
    return updateAboutUs(body)
      .then((res) => {
        toast.success("About Us Updated Successfully");
        dispatch(getAllAboutUsData({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const deleteAboutData = (userDelete,pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_ABOUT_US_REQUEST,
    });
    return deleteAboutUs(userDelete)
      .then((res) => {
        dispatch({
          type: type.DELETE_ABOUT_US_SUCCESS,
          payload: res,
          data: userDelete,
        });
        toast.success("About Us Deleted Successfully");
        dispatch(getAllAboutUsData(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_ABOUT_US_FAILURE,
        });
      });
  };
};

