import React, { useEffect, useState, useRef } from "react";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { getAllComets } from "../../../redux/actions/Comets";
import { useDispatch, useSelector } from "react-redux/es/exports";
import DeleteModal from "../Modals/DeleteModal";
import ViewComet from "../Modals/ViewComet";
import { selectedComet } from "../../../redux/actions/Comets";
import { deleteCometData } from "../../../redux/actions/Comets";
import ExportExcel from "../../Utils/ExportExcel";
import axios from "axios";
import { pick as _pick, map as _map } from "lodash";

const Comet = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShowModal] = useState(false);
  const [shows, setDeleteModal] = useState(false);
  const [rowUserId, setRowUserId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const prevRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    prevRef.current = { page, pageSize };
    dispatch(
      getAllComets({ pageSize: pageSize, pageNumber: page, search: " " })
    );
    (async () => {
      const user = await axios.get(
        "https://apiyouni.rlogical.com/api/Comet/GetComets"
      );
      setData(user?.data?.data);
    })();
  }, [page, pageSize]);

  const getComets = useSelector((state) => state?.comets?.comets);
  const downloadData = _map(data, (obj) =>
    _pick(obj, [
      "cometId",
      "cometName",
      "startDateTime",
      "endDateTime",
      "alarmDateTime",
      "description",
      "note",
      "priority",
    ])
  );

  const deleteUserData = () => {
    dispatch(
      deleteCometData(rowUserId, {
        pageSize: pageSize,
        pageNumber: page,
        search: " ",
      })
    );
    setDeleteModal(false);
  };

  const selectedCometData = (row) => {
    dispatch(selectedComet(row));
    setShowModal(true);
  };
  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(
      getAllComets({ pageSize: pageSize, pageNumber: page, search: value })
    );
  };

  const columns = [
    {
      name: "Comet Id",
      selector: (row) => row.cometId,
      sortable: false,
    },
    {
      name: "Comet Name",
      selector: (row) => row.cometName,
      sortable: false,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.isActive === true ? (
            <>
              <p
              // style={{ backgroundColor: "green", color: "white" }}
              // onClick={handleInActive}
              >
                {" "}
                Active
              </p>
            </>
          ) : (
            <p
            // onClick={handleIsActive}
            >
              InActive
            </p>
          )}
        </>
      ),
    },
    {
      name: "Notes",
      selector: (row) => row.note,
      sortable: false,
    },
    {
      name: "Priority",
      selector: (row) => row.priority,
      sortable: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            type="button"
            onClick={() => selectedCometData(row)}
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            className="delete-cls"
            type="button"
            onClick={() => {
              setDeleteModal(true);
              setRowUserId(row?.cometId);
            }}
            id={row?.cometId}
            data-mode="delete"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Comet</strong> Listing
              </h1>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />
              <ExportExcel data={downloadData} fileName="Comets" />
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={getComets.data}
              onChangeRowsPerPage={(newPageSize) => setPageSize(newPageSize)}
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(newPage) => setPage(newPage)}
              pagination
              paginationTotalRows={getComets?.totalRecords}
              paginationServer
              //expandableRows
              //expandableRowsComponent={ExpandedComponent}
              // selectableRows
              responsive
            />
          </div>
        </div>
      </main>
      <ViewComet show={show} onHide={() => setShowModal(!show)} />
      <DeleteModal
        show={shows}
        deleteUserData={deleteUserData}
        onHide={() => setDeleteModal(!shows)}
      />
    </>
  );
};

export default Comet;
