import axios from "axios";

export const API_URL = "https://apiyouni.rlogical.com/api/";

const getHeaders = () => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "No-Auth-Challenge": true,
    },
  };
};

//GetAll users Data
export const loginUser = async (data) => {
  await axios.post(`${API_URL}StaffManagement/StaffLogin`, data);
};
export const getUsersAllData = async (page) =>
  await axios.get(
    `${API_URL}Authenticate/getNormalUsers?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );

export const activeUser = async (userId) => {
  await axios.post(
    `${API_URL}Authenticate/ActiveInActiveUser`,
    userId,
    getHeaders()
  );
};
export const addUser = async (data) => {
  await axios.post(`${API_URL}Authenticate/Add`, data, getHeaders());
};
export const UpdateUserDetails = async (data) =>
  await axios.put(`${API_URL}Authenticate/updateuser`, data, getHeaders());

export const deleteData = async (id) =>
  await axios.delete(
    `${API_URL}Authenticate/DeleteUser?id=${id}`,
    getHeaders()
  );

export const deleteSubscribtion = async (id) =>
  await axios.delete(
    `${API_URL}Authenticate/DeleteSubscribeUser?SubscribeId=${id}`,
    getHeaders()
  );

//GetAll Corporate Comapny
export const getAllCororateCompany = async (page) =>
  await axios.get(
    `${API_URL}Company/getCompanies?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );

export const activeCompany = async (userId) => {
  await axios.post(
    `${API_URL}Company/ActiveInActiveCompany`,
    userId,
    getHeaders()
  );
};

export const updateCorporateCompany = async (data) =>
  await axios.put(`${API_URL}Company/UpdateCompany`, data, getHeaders());

export const deleteCompany = async (id) =>
  await axios.delete(`${API_URL}Company/DeleteCompany?id=${id}`, getHeaders());

//GetAll Corporate users datas
export const getAllCororateUsers = async (page) =>
  await axios.get(
    `${API_URL}Authenticate/getCorporateUsers?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );

//GetAll All User count datas
export const getAllCount = async () =>
  await axios.get(`${API_URL}Authenticate/GetTotalCounts`, getHeaders());

//GetAll All User count datas
export const getAllCorporateusersCount = async () =>
  await axios.get(
    `${API_URL}Authenticate/GetTotalCorporateUserCount`,
    getHeaders()
  );

//GetAll Stars Data
export const getStarsAllData = async (page) => {
  return await axios.get(
    `${API_URL}Projects/GetProjects?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&startdate=${page?.startdate}&enddate=${page?.enddate}&search=${page?.search}`,
    getHeaders()
  );
};
export const getUpcomingStarsAllData = async (page) => {
  return await axios.get(
    `${API_URL}Projects/GetUpcomingProjects?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );
};
export const deleteProject = async (id) =>
  await axios.delete(`${API_URL}Projects/DeleteProject?id=${id}`, getHeaders());

//GetAll Comets Data
export const getCometsAllData = async (page) =>
  await axios.get(
    `${API_URL}Comet/GetComets?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );

export const getUpcomingCometsAllData = async (page) =>
  await axios.get(
    `${API_URL}Comet/GetUpcomingComets?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );

export const deleteComet = async (id) =>
  await axios.delete(`${API_URL}Comet/DeleteComet?id=${id}`, getHeaders());

//GetAll Contact Us
export const getContactAllData = async (page) => {
  return await axios.get(
    `${API_URL}ContactUs/GetContactInfo?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&startdate=${page?.startdate}&enddate=${page?.enddate}&search=${page?.search}`,
    getHeaders()
  );
};

//GetAll About Us
export const getAboutAllData = async (page) => {
  return await axios.get(
    `${API_URL}AboutUs/getAboutContent?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );
};
export const addAboutUs = async (data) => {
  await axios.post(`${API_URL}AboutUs/AddAbout`, data, getHeaders());
};
export const updateAboutUs = async (data) => {
  await axios.put(`${API_URL}AboutUs/UpdateAbout`, data, getHeaders());
};
export const deleteAboutUs = async (id) =>
  await axios.delete(
    `${API_URL}AboutUs/DeleteAboutContent?id=${id}`,
    getHeaders()
  );

//GetAll Privacy and Policy
export const getPrivacyAllData = async (page) => {
  return await axios.get(
    `${API_URL}PrivacyPolicy/getPrivacyPolicy?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );
};
export const addPrivacy = async (data) => {
  await axios.post(
    `${API_URL}PrivacyPolicy/AddPrivacyPolicy`,
    data,
    getHeaders()
  );
};
export const updatePrivacy = async (data) => {
  await axios.put(`${API_URL}PrivacyPolicy/UpdatePolicy`, data, getHeaders());
};
export const deletePrivacy = async (id) =>
  await axios.delete(
    `${API_URL}PrivacyPolicy/DeletePolicyContent?id=${id}`,
    getHeaders()
  );

//GetAll Privacy and Policy
export const getTermsAllData = async (page) => {
  return await axios.get(
    `${API_URL}TermsConditions/getTermsCondition?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );
};
export const addTerms = async (data) => {
  await axios.post(
    `${API_URL}TermsConditions/AddTermsCondition`,
    data,
    getHeaders()
  );
};
export const updateTerms = async (data) => {
  await axios.put(
    `${API_URL}TermsConditions/UpdateTermsCondition`,
    data,
    getHeaders()
  );
};
export const deleteTerms = async (id) =>
  await axios.delete(
    `${API_URL}TermsConditions/DeleteTermsCondition?id=${id}`,
    getHeaders()
  );

// Staff API
export const getStaffData = async (page) => {
  return await axios.get(
    `${API_URL}StaffManagement/getAllStaff?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );
};
export const addStaff = async (data) => {
  await axios.post(`${API_URL}StaffManagement/AddStaff`, data, getHeaders());
};
export const updateStaff = async (data) => {
  await axios.put(`${API_URL}StaffManagement/UpdateStaff`, data, getHeaders());
};
export const deleteStaff = async (id) =>
  await axios.delete(
    `${API_URL}StaffManagement/DeleteStaff?id=${id}`,
    getHeaders()
  );

// Role and Rights API
export const getRoleData = async (id) => {
  return await axios.get(
    `${API_URL}RoleRights/getRoleRights?staffId=${id}`,
    getHeaders()
  );
};
export const getAllRoleData = async (id) => {
  return await axios.get(`${API_URL}RoleRights/getAllRoleRights`, getHeaders());
};
export const addAssign = async (data) => {
  await axios.post(`${API_URL}RoleRights/AssignRoleRights`, data, getHeaders());
};

export const updateAssign = async (data) => {
  await axios.put(`${API_URL}RoleRights/ReAssignRoleRights`, data);
};

//GetAll Application Page
export const getPgaesAllData = async (page) => {
  return await axios.get(
    `${API_URL}ApplicationPage/getApplicationPage?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );
};
export const addApplicationPage = async (data) => {
  await axios.post(
    `${API_URL}ApplicationPage/AddApplicationPage`,
    data,
    getHeaders()
  );
};
export const updateApplicationPage = async (data) => {
  await axios.put(
    `${API_URL}ApplicationPage/UpdateApplicationPage`,
    data,
    getHeaders()
  );
};
export const deleteApplicationPage = async (id) => {
  await axios.delete(
    `${API_URL}ApplicationPage/DeleteApplicationPage?id=${id}`,
    getHeaders()
  );
};

//GetAll Quotes
export const getQuotesAllData = async (page) => {
  return await axios.get(
    `${API_URL}Quotes/getQuotes?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );
};
export const addQuotes = async (data) => {
  await axios.post(`${API_URL}Quotes/AddQuote`, data, getHeaders());
};
export const updateQuotes = async (data) => {
  await axios.put(`${API_URL}Quotes/UpdateQuote`, data, getHeaders());
};
export const deleteQuotes = async (id) => {
  await axios.delete(`${API_URL}Quotes/DeleteQuote?id=${id}`, getHeaders());
};

//GetAll Blogs
export const getBlogsAllData = async (page) => {
  return await axios.get(
    `${API_URL}OurBlogs/getbBlogs?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );
};
export const addBlogs = async (data) => {
  await axios.post(`${API_URL}OurBlogs/AddBlog`, data, getHeaders());
};
export const updateBlogs = async (data) => {
  await axios.put(`${API_URL}OurBlogs/UpdateBlog`, data, getHeaders());
};
export const deleteBlogs = async (id) => {
  await axios.delete(`${API_URL}OurBlogs/DeleteBlog?id=${id}`, getHeaders());
};

//GetAll Community
export const getCommunityAllData = async (page) => {
  return await axios.get(
    `${API_URL}OurCommunity/getOurCommunity?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );
};
export const addCommunity = async (data) => {
  await axios.post(`${API_URL}OurCommunity/AddCommunity`, data, getHeaders());
};
export const updateCommunity = async (data) => {
  await axios.put(`${API_URL}OurCommunity/UpdateCommunity`, data, getHeaders());
};
export const deleteCommunity = async (id) => {
  await axios.delete(
    `${API_URL}OurCommunity/DeleteCommunity?id=${id}`,
    getHeaders()
  );
};

//GetAll Subscription
export const getSubscriptionAllData = async (page) => {
  return await axios.get(
    `${API_URL}Authenticate/GetSubscribedUsers?pageNumber=${page?.pageNumber}&pageSize=${page?.pageSize}&search=${page?.search}`,
    getHeaders()
  );
};
export const getSubscriptionByUser = async (id) => {
  return await axios.get(
    `${API_URL}Authenticate/GetUserSubscriptions?pageNumber=1&pageSize=10&userid=${id}`,
    getHeaders()
  );
};

export const updateSubscribeData = async (data) =>
  await axios.put(`${API_URL}Authenticate/updateuser`, data, getHeaders());
