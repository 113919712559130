import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, selectedUser } from "../../../redux/actions/getUsers";
import ViewUserModal from "../Modals/ViewUserModal";
import DeleteModal from "../Modals/DeleteModal";
import { deleteUser } from "../../../redux/actions/getUsers";
import axios from "axios";
import ExportExcel from "../../Utils/ExportExcel";
import { activeInactiveUser } from "../../../redux/actions/getUsers";
import EditUser from "../Modals/EditUser";
import moment from "moment";
import AddUser from "../Modals/AddUser";
import { pick as _pick, map as _map } from "lodash";

const Users = () => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
  });

  const [show, setShowModal] = useState(false);
  const [shows, setDeleteModal] = useState(false);
  const [rowUserId, setRowUserId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [data, setData] = useState([]);
  const [mode, setModalMode] = useState("");
  const [addUser, setAddUser] = useState(false);
  const [edit, setEditModal] = useState(false);
  const dispatch = useDispatch();
  const AllUsers = useSelector((state) => state?.users?.users);

  const getHeaders = () => {
    const token = localStorage.getItem("token");
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "No-Auth-Challenge": true,
      },
    };
  };

  useEffect(() => {
    dispatch(getAllUsers(pagination));
    (async () => {
      const user = await axios.get(
        "https://apiyouni.rlogical.com/api/Authenticate/getNormalUsers",
        getHeaders()
      );
      setData(user?.data?.data);
    })();
  }, [pagination]);

  //download data with particular fields
  const downloadData = _map(data, (obj) =>
    _pick(obj, [
      "userid",
      "username",
      "dob",
      "skypeName",
      "emailId",
      "loginType",
      "isActive",
    ])
  );

  // Used to Delete User
  const deleteUserData = () => {
    dispatch(deleteUser(rowUserId, { ...pagination, search: "" }));
    setDeleteModal(false);
  };

  //selected user data
  const selectedUsers = (row) => {
    dispatch(selectedUser(row));
    setShowModal(true);
  };

  //search user
  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(getAllUsers({ ...pagination, search: value }));
  };

  //add user modal
  const addNormalUser = (e) => {
    var mode = e.currentTarget.dataset.mode;
    setAddUser(true);
    setModalMode(mode);
  };

  //edit user data
  const editUserData = (row) => {
    setEditModal(true);
    dispatch(selectedUser(row));
  };

  //active inactive user
  const handleIsActive = (e, row) => {
    if (row.userid) {
      setIsActive(true);
      dispatch(
        activeInactiveUser(
          {
            UserId: row?.userid,
            IsActive: e.target.checked,
          },
          pagination
        )
      );
    } else {
      setIsActive(false);
    }
  };

  const columns = [
    {
      name: "User Id",
      selector: (row) => row.userid,
      sortable: false,
      width: "90px",
    },
    {
      name: "User Name",
      selector: (row) => row.username,
      sortable: false,
    },
    {
      name: "User Image",
      selector: (row) => (
        <img
          src={row.photo}
          onClick={() => window.open(row?.photo, "_blank")}
        />
      ),
      sortable: false,
    },
    {
      name: "Skype Name",
      selector: (row) => row.skypeName,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.emailId,
      sortable: false,
    },
    {
      name: "Login Type",
      selector: (row) => row.loginType,
      sortable: false,
    },
    {
      name: "Status",
      width: "100px",
      selector: (row) => (
        <>
          {row?.userid && (
            <>
              <label class="switch">
                <input
                  type="checkbox"
                  checked={row.isActive ? isActive : false}
                  onChange={(e) => handleIsActive(e, row)}
                />
                <span class="slider round"></span>
              </label>
            </>
          )}
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            onClick={() => selectedUsers(row)}
            type="button"
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            className="edit-cls"
            type="button"
            onClick={() => {
              editUserData(row);
            }}
            id={row.userid}
            data-mode="edit"
          >
            <FontAwesomeIcon
              icon={faEdit}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            type="button"
            className="delete-cls"
            id={row.userid}
            onClick={() => {
              setDeleteModal(true);
              setRowUserId(row.userid);
            }}
            data-mode="delete"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Normal User</strong> Listing
              </h1>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />
              <ExportExcel data={downloadData} fileName="Users" />
              <button
                className="btn btn-primary"
                data-mode="add"
                onClick={addNormalUser}
              >
                Add
              </button>
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={AllUsers?.data}
              onChangeRowsPerPage={(newPageSize) =>
                setPagination({ ...pagination, pageSize: newPageSize })
              }
              onChangePage={(newPage) =>
                setPagination({ ...pagination, pageNumber: newPage })
              }
              pagination
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              paginationTotalRows={AllUsers?.totalRecords}
              paginationServer
              responsive
            />
          </div>
        </div>
      </main>
      <AddUser show={addUser} onHide={() => setAddUser(!addUser)} />
      <ViewUserModal show={show} onHide={() => setShowModal(!show)} />
      <EditUser show={edit} onHide={() => setEditModal(!edit)} />
      <DeleteModal
        show={shows}
        deleteUserData={deleteUserData}
        onHide={() => setDeleteModal(!shows)}
      />
    </>
  );
};

export default Users;
