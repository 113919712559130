import {
  getAllCororateUsers,
  getAllCorporateusersCount,
  getAllCount,
  getUsersAllData,
  deleteData,
  activeUser,
  UpdateUserDetails,
  addUser,
  getSubscriptionAllData,
} from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";
import { getAllSubscriptionData } from "./subscription";

const requestUsersData = () => {
  return {
    type: type.GET_USERS_REQUEST,
  };
};

const successUsers = (users) => {
  return {
    type: type.GET_USERS_SUCCESS,
    payload: users,
  };
};

const errorUsers = (error) => {
  return {
    type: type.GET_USERS_FAILURE,
    message: error,
  };
};

const successCorporateUser = (corporateUser) => {
  return {
    type: type.GET_CORPORATE_USERS_SUCCESS,
    payload: corporateUser,
  };
};

const successAllusersCount = (userCount) => {
  return {
    type: type.GET_USERS_COUNT_SUCCESS,
    payload: userCount,
  };
};

const successAllCorporateusersCount = (corporateUserCount) => {
  return {
    type: type.GET_CORPORATE_USERS_COUNT_SUCCESS,
    payload: corporateUserCount,
  };
};

export const selectedUser = (user) => {
  return {
    type: type.SELECTED_USER_SUCCESS,
    payload: user,
  };
};

const addUserDetail = (data) => ({
  type: type.ADD_USER,
  data,
});

const updateUser = (data) => ({
  type: type.UPDATE_USER,
  data,
});

export const getAllUsers = (pageObj) => {
  return function (dispatch) {
    dispatch(requestUsersData());
    getUsersAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successUsers(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorUsers(error));
        }
      })
      .catch((errorMasg) => {
        const error = "API is not working";
        console.log(errorMasg);
      });
  };
};

export const addUserData = (body, onHide) => {
  return (dispatch) => {
    return addUser(body)
      .then((res) => {
        toast.success("User Added Successfully");
        onHide();
        dispatch(getAllUsers({ pageNumber: 1, pageSize: 10, search: "" }));
        dispatch(
          getAllCorporateUserData({ pageNumber: 1, pageSize: 10, search: "" })
        );
      })
      .catch((err) => {
        dispatch(errorUsers(err?.response?.data));
        toast.error(err?.response?.data?.message);
      });
  };
};

export const editUserData = (body) => {
  return (dispatch) => {
    return UpdateUserDetails(body)
      .then((res) => {
        toast.success("User Updated Successfully");
        dispatch(updateUser(res.data));
        dispatch(getAllUsers({ pageNumber: 1, pageSize: 10, search: "" }));
        dispatch(
          getAllCorporateUserData({ pageNumber: 1, pageSize: 10, search: "" })
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const getAllCorporateUserData = (pageObj) => {
  return function (dispatch) {
    getAllCororateUsers(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successCorporateUser(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorUsers(error));
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const deleteCorporateUser = (userDelete, pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_CORPORATE_USERS_REQUEST,
    });
    return deleteData(userDelete)
      .then((res) => {
        toast.success("Corporate User Deleted Successfully");
        dispatch({
          type: type.DELETE_CORPORATE_USERS_SUCCESS,
          payload: res,
          data: userDelete,
        });
        dispatch(getAllCorporateUserData(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_CORPORATE_USERS_FAILURE,
        });
      });
  };
};

export const getAllCountData = () => {
  return function (dispatch) {
    getAllCount()
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successAllusersCount(resp.data.data));
        } else {
          const error = "Not data found";
          dispatch(errorUsers(error));
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const getAllCorporateUserCountData = () => {
  return function (dispatch) {
    getAllCorporateusersCount()
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successAllCorporateusersCount(resp.data.totalUsers));
        } else {
          const error = "Not data found";
          dispatch(errorUsers(error));
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const activeInactiveUser = (userId, pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.ACTIVE_USER_REQUEST,
    });
    return activeUser(userId)
      .then((res) => {
        if (userId?.IsActive) {
          toast.success("User Activate Successfully");
        } else {
          toast.success("User Deactivate Successfully");
        }
        dispatch({
          type: type.ACTIVE_USER_SUCCESS,
          payload: res,
          data: userId,
        });
        dispatch(getAllUsers(pageObj));
        dispatch(getAllCorporateUserData(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.ACTIVE_USER_FAILURE,
        });
      });
  };
};

export const deleteUser = (userDelete, pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_USER_REQUEST,
    });
    return deleteData(userDelete)
      .then((res) => {
        toast.success("User Deleted Successfully");
        dispatch({
          type: type.DELETE_USER_SUCCESS,
          payload: res,
          data: userDelete,
        });
        dispatch(getAllUsers(pageObj));
        dispatch(getAllSubscriptionData(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_USER_FAILURE,
        });
      });
  };
};
