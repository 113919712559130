import * as type from "../type";

const initialState = {
  subscription: [],
  loading: false,
  error: "",
  selectedSubscription:null,
  subscriptionUser: [],
};

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_SUBSCRIPTION_SUCCESS:
      return{
        ...state,
        selectedSubscription: action?.payload
      }
      case type.CLEAR_SUBSCRIPTION_DATA:
        return{
          ...state,
          selectedSubscription: []
        }
    case type.SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        subscription: action.payload,
      };

    case type.SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

      case type.SUBSCRIPTION_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.SUBSCRIPTION_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        subscriptionUser: action.payload,
      };

    case type.SUBSCRIPTION_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    default:
      return state;
  }
};

export default subscription;
