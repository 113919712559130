// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD3Mn1DXDxgRxBB7-Lm0_O4se22uQZxLWM",
  authDomain: "youni-admin-8b0d1.firebaseapp.com",
  projectId: "youni-admin-8b0d1",
  storageBucket: "youni-admin-8b0d1.appspot.com",
  messagingSenderId: "506801830446",
  appId: "1:506801830446:web:d3a3181c533b3a8caf7dbf",
  measurementId: "G-Q8610RWW75",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
