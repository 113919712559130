import * as type from "../type";

const initialState = {
  planets: [],
  loading: false,
  error: "",
};

const planets = (state = initialState, action) => {
  switch (action.type) {
    case type.PLANETS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.PLANETS_SUCCESS:
      return {
        ...state,
        loading: false,
        planets: action.payload,
      };

    case type.PLANETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    default:
      return state;
  }
};

export default planets;
