import * as type from "../type";

const initialState = {
  quotes: [],
  loading: false,
  error: "",
  selectedQuotes: null,
};

const quotes = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_QUOTES_SUCCESS:
      return {
        ...state,
        selectedQuotes: action?.payload
      }
    case type.CLEAR_QUOTE_STATE:
      return {
        ...state,
        selectedQuotes: []
      }
    case type.QUOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.QUOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        quotes: action.payload,
      };

    case type.QUOTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.DELETE_QUOTES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case type.DELETE_QUOTES_SUCCESS: {
      return {
        ...state,
        quotes: (state?.quotes?.data).filter((singleUser) => {
          return singleUser?.quoteId !== action?.payload?.data
        }
        ),
        loading: true,
        error: "",
      };

    }
    case type.DELETE_QUOTES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default quotes;
