import React from "react";
import { Modal } from "../Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";

const ViewCommunity = ({show, onHide}) => {

  const selectData = useSelector((state) => state?.ourCommunity?.selectedCommunity);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: "CLEAR_COMMUNITY_STATE" })
    onHide();
  }

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View Community Details"
        footer={<Button onClick={handleClose}>Cancel</Button>}
      >
        <div className="center">
          <p> Id : {selectData?.id}</p>
          <p> Description : {selectData?.communityDesc}</p>
          {selectData?.imageUrl1 && <p> Image 1 : <img src={selectData?.imageUrl1}  onClick={()=> window.open(selectData?.imageUrl1, "_blank")} /><br /></p>}
          {selectData?.imageUrl2 && <p> Image 2 : <img src={selectData?.imageUrl2} onClick={()=> window.open(selectData?.imageUrl2, "_blank")}/><br /></p>}
          {selectData?.imageUrl3 && <p> Image 3 : <img src={selectData?.imageUrl3} onClick={()=> window.open(selectData?.imageUrl3, "_blank")}/><br /></p>}
          {selectData?.imageUrl4 && <p> Image 4 : <img src={selectData?.imageUrl4} onClick={()=> window.open(selectData?.imageUrl4, "_blank")}/><br /></p>}
          <p> Date : {moment(selectData?.createdDate).format('DD-MM-YYYY')}</p>
        </div>
      </Modal>
    </>
  )
}

export default ViewCommunity