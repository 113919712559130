import React from "react";
import { useNavigate } from "react-router-dom";

// import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function PageNotFound() {
  // used for navigate route
  const history = useNavigate();

  // submit Login form
  const onBack = (e) => {
    e.preventDefault();
    history("/dashboard");
  };

  return (
    <>
      <main className="d-flex w-100 h-100 backgroundImage">
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center">
                  <p className="h2 font-weight-normal mt-3 mb-4">Coming Soon</p>
                  <a
                    href="/dashboard"
                    className="btn btn-primary btn-lg"
                    onClick={onBack}
                  >
                    Return to website
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default PageNotFound;
