import * as type from "../type";
import axios from "axios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../../../src/firebase-config";
import {loginUser} from "../../api/api"
import { toast } from "react-toastify";

const requestLogin = () => {
  return {
    type: type.ADMIN_LOGIN_REQUEST,
  };
};

const successLogin = (admin) => {
  return {
    type: type.ADMIN_LOGIN_SUCCESS,
    payload: admin,
  };
};

const errorLogin = (error) => {
  return {
    type: type.ADMIN_LOGIN_FAILURE,
    message: error,
  };
};

export const loginAdmin = (data) => {
  return function (dispatch) {
    axios({
      method: "post",
      url: "https://apiyouni.rlogical.com/api/StaffManagement/StaffLogin",
      headers: { "Content-Type": "application/json-patch+json" },
      data: data,
    })
      .then(function (response) {
        const data = JSON.stringify(response.data.data.staffId)
        localStorage.setItem("accessToken",data);
        localStorage.setItem('token',response?.data?.token);
        dispatch(successLogin(response.data?.data));
        toast.success("Login Sucessfully");
      })
      .catch(function (error) {
        let message = "";
            if (error.code === "ERR_BAD_REQUEST") {
              message = "No Staff found for the entered Email Id";
              toast.error(message);
            }
        console.log(error);
      });

  };
};
