import React, { useEffect } from "react";

// import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {
  getAllCountData,
} from "../../../redux/actions/getUsers";
import { useDispatch, useSelector } from "react-redux";

function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCountData());
  }, [dispatch]);

  const totalCount = useSelector((state) => state.users.userCount);

  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3"> Dashboard</h1>

          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="card-box bg-blue">
                <div className="inner">
                  <h3> {totalCount?.totalNormalUsers} </h3>
                  <p> Total Normal Users </p>
                </div>
                <div className="icon">
                  <i
                    className="fa fa-graduation-cap"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="card-box bg-green">
                <div className="inner">
                  <h3> {totalCount?.totalSubscribedUsers} </h3>
                  <p> Total Subscribe Users </p>
                </div>
                <div className="icon">
                  <i className="fa fa-money" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card-box bg-orange">
                <div className="inner">
                  <h3> {totalCount?.totalCorporateUsers} </h3>
                  <p> Total Corporate Users </p>
                </div>
                <div className="icon">
                  <i className="fa fa-user-plus" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card-box bg-red">
                <div className="inner">
                  <h3> {totalCount?.totalRevenue}</h3>
                  <p> Revenue </p>
                </div>
                <div className="icon">
                  <i className="fa fa-users"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Dashboard;
