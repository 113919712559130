import * as type from "../type";

const initialState = {
  contact: [],
  loading: false,
  error: "",
  selectedContact:null,
};

const contact = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_CONTACT_SUCCESS:
      return{
        ...state,
        selectedContact: action?.payload
      }
    case type.CONTACT_US_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        contact: action.payload,
      };

    case type.CONTACT_US_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    default:
      return state;
  }
};

export default contact;
