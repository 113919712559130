import React, { useState, useEffect } from 'react'
import { Modal } from '../Modal'
import Button from 'react-bootstrap/Button';
import isEmpty from '../../validation/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { addPrivacyData, updatePrivacyData } from '../../../../redux/actions/privacyPolicy';

const AddPrivacy = ({show,onHide}) => {

  const [privacyErr, setPrivacyErr] = useState('')
  const initialValues = {
    policyDetail: "",
    isActive: false,
  };

  const [state, setState] = useState({ ...initialValues })
  const dispatch = useDispatch();

  const selectData = useSelector((state) => state?.privacyPolicy?.selectedPrivacy);
  useEffect(() => {
    setState(selectData);
  }, [selectData]);

  const handleChange = (e) => {

    if (e.target.name === "policyDetail") {
      setPrivacyErr("");
    }

    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;

    if (isEmpty(state?.policyDetail)) {
      setPrivacyErr("Please enter description");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid && !selectData?.id) {
      const data = {
        policyDetail: state?.policyDetail,
        isActive: state?.isActive ? state?.isActive : false,
        createdBy: 0,
      }
      dispatch(addPrivacyData(data))
      handleClose();
    } else if(isValid) {
      const data = {
        Id: state?.id,
        PolicyDetail: state?.policyDetail,
        IsActive: state?.isActive,
      }
      const formData = new FormData();
      for (let key in data) {
        if (data[key] !== null) {
          formData.append(key, data[key]);
        }
      }
      dispatch(updatePrivacyData(formData))
      handleClose()
    }

  }

  const handleClose = () => {
    dispatch({type: "CLEAR_PRIVACY_STATE"})
    setPrivacyErr("")
    onHide();
  }

  const handleActive = (event) => {
    if (event.target.checked) {
      setState({ ...state, ["isActive"]: true });
    } else {
      setState({ ...state, ["isActive"]: false });
    }
  }

  return (
     <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title={state?.id ? "Update Privacy Policy Detail" : "Add Privacy Policy Detail"}
        footer={
          <>
            <Button onClick={handleSubmit}>{state?.id ? "Update" : "Add"}</Button>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          </>}
      >
        <label className="form-label">Policy Detail</label>
        <textarea
          className="form-control form-control-lg"
          name="policyDetail" rows="4" cols="93"
          placeholder='Please enter policy detail'
          value={state?.policyDetail}
          onChange={handleChange}
        />
        {privacyErr && <span className="error-msg">{privacyErr}</span>}
        <br/>
        <label className="form-label">Active </label>{" "}
        <label class="switch">
          <input type="checkbox"
            checked={
              state?.isActive
                ? state?.isActive
                : false
            }
            onChange={handleActive} />
          <span class="slider round"></span>
        </label>
      </Modal>
    </>
  )
}

export default AddPrivacy