import React from 'react'
import { Modal } from './Modal'
import Button from 'react-bootstrap/Button';

const DeleteSubscribeModal = ({ show, onHide, deleteUserData, deleteSubscribtion }) => {
  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title=" "
        footer={
          <>
            <Button onClick={deleteSubscribtion}>Delete Subscribtion</Button>
            <Button onClick={deleteUserData}>Delete User</Button>
            <Button variant="secondary" onClick={onHide}>Cancel</Button>
          </>}
      >
        <p className="text-center">Are you sure you want to Delete ?</p>
      </Modal>
    </>
  )
}

export default DeleteSubscribeModal

