import React, { useState, useEffect } from 'react'
import { Modal } from '../Modal'
import Button from 'react-bootstrap/Button';
import { addAboutData, updatAboutData } from '../../../../redux/actions/aboutUs';
import isEmpty from '../../validation/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../../../../firebase-config';
import { addApplicationPageData, updatApplicationPageData } from '../../../../redux/actions/applicationPage';
import { toast } from "react-toastify";
import { faSlash } from '@fortawesome/free-solid-svg-icons';

const AddApplicationPage = ({ show, onHide }) => {

  const [aboutErr, setAboutErr] = useState('')
  const initialValues = {
    videoUrl: "",
    description: "",
    isActive: false,
  };

  const [state, setState] = useState({ ...initialValues })
  const [video, setVideo] = useState(null);
  const [percent, setPercent] = useState(0)
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const selectData = useSelector((state) => state?.applicationPage?.selectedPages);

  useEffect(() => {
    setState(selectData);
  }, [selectData]);

  const handleChange = (e) => {

    if (e.target.name === "description") {
      setAboutErr("");
    }

    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const validateNormalUserForm = () => {
    let isValid = true;

    if (isEmpty(state?.description)) {
      setAboutErr("Please enter description");
      isValid = false;
    }

    return isValid;
  };

  const handleVideoChange = (e) => {
    setLoader(true);
    const file = e.target.files[0];
    if (!file.name.match(/.(mp4|mov|mkv)$/i)) {
      toast.error("This file format is not supported");
      setLoader(false);
    } else {
      setVideo(file);
      file["id"] = Math.random();
      const promises = [];
      const storageRef = ref(storage, `/application_page_photo/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            let URLS = url;
            if (URLS) {
              setVideo(URLS);
              setLoader(false);
            }
            setState({ ...state, ["videoUrl"]: URLS });
          });
        }
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateNormalUserForm();
    if (isValid && !selectData?.id) {
      const data = {
        Description: state?.description,
        VideoUrl: state?.videoUrl,
        isActive: state?.isActive
          ? state?.isActive
          : false,
        createdBy: 0,
      }
      dispatch(addApplicationPageData(data))
      handleClose();
    } else if (isValid) {
      const data = {
        Id: state?.id,
        Description: state?.description,
        ImageUrl: state?.imageUrl,
        VideoUrl: state?.videoUrl,
        IsActive: state?.isActive,
        UpdatedBy: 1,
      }
      const formData = new FormData();
      for (let key in data) {
        if (data[key] !== null) {
          formData.append(key, data[key]);
        }
      }
      dispatch(updatApplicationPageData(formData))
      setVideo(' ')
      handleClose()
    }

  }

  const handleClose = () => {
    dispatch({ type: "CLEAR_APPLICATION_STATE" })
    setAboutErr("")
    onHide();
  }

  const handleActive = (event) => {
    if (event.target.checked) {
      setState({ ...state, ["isActive"]: true });
    } else {
      setState({ ...state, ["isActive"]: false });
    }
  }

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow common-popup-wrapper"
        onHide={onHide}
        title={state?.id ? "Update Application Page" : "Add Application Page"}
        footer={
          <>
            <Button onClick={handleSubmit}>{state?.id ? "Update" : "Add"}</Button>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          </>}
      >
        <div className='fit-image'>
          <video
            src={state?.videoUrl}
            alt="User Profile"
          ></video>

          <div className="input_file">
            <input
              className="edit-file"
              type="file"
              id="file"
              accept="video/*"
              name="videoUrl"
              onChange={handleVideoChange}
            />
            Upload Video
          </div>
          {loader && (
            <>
              {" "}
              <div className="cssloader_main">
                {" "}
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>{" "}
                </div>{" "}
              </div>
            </>
          )}
        </div>
        <label className="form-label">Description </label>
        <textarea
          className='textarea'
          name="description" rows="4" cols="93"
          placeholder='Please enter description'
          value={state?.description}
          onChange={handleChange}
        />
        {aboutErr && <span className="error-msg">{aboutErr}</span>}<br />
        <label className="form-label">Active </label>{" "}
        <label class="switch">
          <input type="checkbox"
            checked={
              state?.isActive
                ? state?.isActive
                : false
            }
            onChange={handleActive} />
          <span class="slider round"></span>
        </label>
      </Modal>
    </>
  )
}

export default AddApplicationPage