import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addCorporateUser,
  getAllCorporateCompanyactionfnc,
} from "../../../redux/actions/corporateUser";

function CorporateModal({ show, mode, handleClose }) {
  const dispatch = useDispatch();
  const [title, setModalTitle] = useState(false);
  const [buttonTitle, setModalBtnTitle] = useState("");
  const [buttonCls, setModalBtnClass] = useState("");
  const [corpoIdErr, setCorpoIdErr] = useState("");
  const [compErr, setCompErr] = useState("");
  const [descErr, setDescErr] = useState("");
  const [maxUserErr, setMaxUserErr] = useState("");
  const [status, checkOperation] = useState(false);
  const autoCloseModal = useRef(null);

  const initialValues = {
    corporateId: "",
    companyName: "",
    description: "",
    maxUsers: "",
    macAddress: "",
  };

  const [coporate, setCorporate] = useState(initialValues);

  const handleChange = (e) => {
    if (e.target.name === "corporateId") {
      setCorpoIdErr("");
    }

    if (e.target.name === "companyName") {
      setCompErr("");
    }

    if (e.target.name === "description") {
      setDescErr("");
    }

    if (e.target.name === "maxUsers") {
      setMaxUserErr("");
    }
    setCorporate({
      ...coporate,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setModeOfFunction(mode);

    // if (status) {
    //   setTimeout(() => {
    //     autoCloseModal.current.click();
    //   }, 1000);
    // }

    setTimeout(() => {
      checkOperation(false);
    }, 500);
  }, [mode, closeModalWindow]);

  function closeModalWindow() {
    handleClose(false);
    setCorporate(initialValues);
    setCorpoIdErr("");
    setCompErr("");
    setDescErr("");
    setMaxUserErr("");
  }

  const setModeOfFunction = (mode) => {
    let title,
      btnTitle,
      btnCls = "";
    if (mode === "add") {
      title = "Add Corporate Company";
      btnTitle = "ADD";
      btnCls = "btn btn-success";
    }

    setModalTitle(title);
    setModalBtnTitle(btnTitle);
    setModalBtnClass(btnCls);
  };

  const formValidation = () => {
    let isValid = true;

    if (!coporate.corporateId) {
      setCorpoIdErr("Please enter corporate id");
      isValid = false;
    }

    if (!coporate.companyName) {
      setCompErr("Please enter compnay N\name");
      isValid = false;
    }

    if (!coporate.description) {
      setDescErr("Please enter description");
      isValid = false;
    }

    if (!coporate.maxUsers || coporate.maxUsers == 0) {
      setMaxUserErr("Please enter maximum user");
      isValid = false;
    }

    if (coporate.maxUsers > 100) {
      setMaxUserErr("Maximum user should be 100 or less");
      isValid = false;
    }

    return isValid;
  };

  const onAddCorporateUser = (e) => {
    e.preventDefault();

    const isValid = formValidation();

    if (isValid) {
      dispatch(addCorporateUser(coporate, closeModalWindow));
      checkOperation(true);
      setDescErr("");
      setMaxUserErr("");
    }
  };

  return (
    <>
      <Modal show={show} centered>
        <Modal.Header>
          <Modal.Title id="sign-in-title">{title}</Modal.Title>
        </Modal.Header>

        {/* Add User Code */}
        {mode === "add" ? (
          <form id="userForm" method="put">
            <Modal.Body>
              <div className="mb-4">
                <label className="form-label">Enter Corporate ID</label>
                <input
                  className="form-control form-control-lg"
                  type="text"
                  name="corporateId"
                  placeholder="Enter Corporate ID"
                  autoComplete="off"
                  onChange={handleChange}
                />
                {corpoIdErr && (
                  <span style={{ color: "red" }}>{corpoIdErr}</span>
                )}
              </div>
              <div className="mb-4">
                <label className="form-label">Enter Company Name</label>
                <input
                  className="form-control form-control-lg"
                  type="text"
                  name="companyName"
                  placeholder="Enter Company Name"
                  autoComplete="off"
                  onChange={handleChange}
                />
                {compErr && <span style={{ color: "red" }}>{compErr}</span>}
              </div>
              <div className="mb-4">
                <label className="form-label">Enter Description</label>
                <textarea
                  className="form-control form-control-lg"
                  type="text"
                  name="description"
                  placeholder="Enter Description"
                  autoComplete="off"
                  onChange={handleChange}
                />
                {descErr && <span style={{ color: "red" }}>{descErr}</span>}
              </div>
              <div className="mb-4">
                <label className="form-label">Enter Maximum User</label>
                <input
                  className="form-control form-control-lg"
                  type="number"
                  name="maxUsers"
                  placeholder="Enter Maximum User"
                  autoComplete="off"
                  onChange={handleChange}
                  max={100}
                  pattern="^-?[0-9]\d*\.?\d*$"
                />
                {maxUserErr && (
                  <span style={{ color: "red" }}>{maxUserErr}</span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-center mr-5">
                <button
                  type="button"
                  className={buttonCls}
                  onClick={onAddCorporateUser}
                >
                  {buttonTitle}
                </button>
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-secondary"
                  ref={autoCloseModal}
                  onClick={closeModalWindow}
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          </form>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
}

export default CorporateModal;
