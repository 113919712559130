import { addCommunity, deleteCommunity, getCometsAllData, getCommunityAllData, updateCommunity } from "../../api/api";
import * as type from "../type";
import { toast } from "react-toastify";

const requestCommunityData = () => {
  return {
    type: type.COMMUNITY_REQUEST,
  };
};

const successCommunity = (community) => {
  return {
    type: type.COMMUNITY_SUCCESS,
    payload: community,
  };
};

const errorCommunity = (error) => {
  return {
    type: type.COMMUNITY_FAILURE,
    message: error,
  };
};

export const selectedCommunity = (data) => {
  return {
    type: type.SELECTED_COMMUNITY_SUCCESS,
    payload: data
  };
};

export const getAllCommunityData = (pageObj) => {
  return function (dispatch) {
    dispatch(requestCommunityData());
    getCommunityAllData(pageObj)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(successCommunity(resp.data));
        } else {
          const error = "Not data found";
          dispatch(errorCommunity(error));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const addCommunityData = (body) => {
  return (dispatch) => {
    return addCommunity(body)
      .then((res) => {
        toast.success("Community Added Successfully");
        dispatch(getAllCommunityData({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const updatCommunityData = (body) => {
  return (dispatch) => {
    return updateCommunity(body)
      .then((res) => {
        toast.success("Community Updated Successfully");
        dispatch(getAllCommunityData({ pageNumber: 1, pageSize: 10, search: "" }))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
};

export const deleteCommunityData = (userDelete,pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_COMMUNITY_REQUEST,
    });
    return deleteCommunity(userDelete)
      .then((res) => {
        dispatch({
          type: type.DELETE_COMMUNITY_SUCCESS,
          payload: res,
          data: userDelete,
        });
        toast.success("Community Deleted Successfully");
        dispatch(getAllCommunityData(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_COMMUNITY_FAILURE,
        });
      });
  };
};

