import * as type from "../type";

const initialState = {
  blogs: [],
  loading: false,
  error: "",
  selectedBlog: null,
};

const ourBlogs = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECTED_BLOGS_SUCCESS:
      return {
        ...state,
        selectedBlog: action?.payload
      }
    case type.CLEAR_BLOG_STATE:
      return {
        ...state,
        selectedBlog: []
      }
    case type.BLOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        isLogin: true,
        blogs: action.payload,
      };

    case type.BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.DELETE_BLOGS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case type.DELETE_BLOGS_SUCCESS: {
      return {
        ...state,
        blogs: (state?.blogs?.data).filter((singleUser) => {
          return singleUser?.id !== action?.payload?.data
        }
        ),
        loading: true,
        error: "",
      };

    }
    case type.DELETE_BLOGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default ourBlogs;
