import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

// import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faProjectDiagram,
  faRocket,
  faUsers,
  faBook,
  faAd,
  faClipboard,
  faBuilding,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoleDetails } from "../../../redux/actions/roleRights";
// import { map } from "lodash";

function Sidebar() {

  const dispatch = useDispatch();
  const staffId = localStorage.getItem("accessToken")

  useEffect(() => {
    dispatch(getAllRoleDetails())
  }, [dispatch]);

  const roleAllData = useSelector((state) => state?.roleRights?.allRoles?.data)
  const roleRights = roleAllData?.find(obj => {
    return obj?.staffId == staffId;
  });
  return (
    <>
      <nav id="sidebar" className="sidebar js-sidebar">
        <div className="sidebar-content js-simplebar">
          <a className="sidebar-brand">
            <span className="align-middle">Youni Admin</span>
          </a>
          <ul className="sidebar-nav">
            <li className="sidebar-header">Pages</li>
            {roleRights?.dashboard === true &&
              <NavLink
                to="/dashboard"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faUser} className="align-middle" />
                  <span className="align-middle">Dashboard</span>
                </span>
              </NavLink>
            }
            {roleRights?.users === true &&
              <NavLink
                to="/users"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faUser} className="align-middle" />
                  <span className="align-middle">Normal Users</span>
                </span>
              </NavLink>
            }
            {roleRights?.corporateUsers === true &&
              <NavLink
                to="/coroprate-user"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faUsers} className="align-middle" />
                  <span className="align-middle">Corporate Users</span>
                </span>
              </NavLink>
            }
            {roleRights?.subscribedusers === true &&
              <NavLink
                to="/subscriptions"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faRocket} className="align-middle" />
                  <span className="align-middle">Subscribed User</span>
                </span>
              </NavLink>
            }
            {roleRights?.corporateCompany === true &&

              <NavLink
                to="/coroprate"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faBuilding} className="align-middle" />
                  <span className="align-middle">Corporate Company</span>
                </span>
              </NavLink>
            }
            {roleRights?.projects === true &&
              <NavLink
                to="/projects"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon
                    icon={faProjectDiagram}
                    className="align-middle"
                  />
                  <span className="align-middle">Projects</span>
                </span>
              </NavLink>
            }
            {roleRights?.upcomingProjects === true &&
              <NavLink
                to="/upcoming/projects"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faUser} className="align-middle" />
                  <span className="align-middle">Upcoming Projects</span>
                </span>
              </NavLink>
            }
            {roleRights?.comets === true &&
              <NavLink
                to="/comets"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon
                    icon={faProjectDiagram}
                    className="align-middle"
                  />
                  <span className="align-middle">Comets</span>
                </span>
              </NavLink>
            }

            {roleRights?.upcomingComets === true &&
              <NavLink
                to="/upcoming/comets"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon
                    icon={faProjectDiagram}
                    className="align-middle"
                  />
                  <span className="align-middle">Upcoming Comets</span>
                </span>
              </NavLink>
            }

            {roleRights?.staff === true &&
              <NavLink
                to="/staff"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faClipboard} className="align-middle" />
                  <span className="align-middle">Staff Management</span>
                </span>
              </NavLink>
            }
            {/* {roleRights?.advertisement === true &&
              <NavLink
                to="/dvertisement"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faAd} className="align-middle" />
                  <span className="align-middle">Advertisement</span>
                </span>
              </NavLink>
            } */}
            {roleRights?.cms === true &&
              <div class="dropdown menu-dropdown">
                <button class="btn dropdown-toggle sidebar-dropdown" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <FontAwesomeIcon icon={faBook} className="align-middle" /> CMS
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <NavLink
                    to="/applicationpages"
                    className="sidebar-item"
                    activeclassname="active"
                  >
                    <a class="dropdown-item" href="#"><FontAwesomeIcon icon={faAngleRight} />Application Page</a>
                  </NavLink>
                  <NavLink
                    to="/community"
                    className="sidebar-item"
                    activeclassname="active"
                  >
                    <a class="dropdown-item" href="#"><FontAwesomeIcon icon={faAngleRight} />Our Community</a>
                  </NavLink>
                  <NavLink
                    to="/blogs"
                    className="sidebar-item"
                    activeclassname="active"
                  >
                    <a class="dropdown-item" href="#"><FontAwesomeIcon icon={faAngleRight} />Our Blog</a>
                  </NavLink>
                  <NavLink
                    to="/quotes"
                    className="sidebar-item"
                    activeclassname="active"
                  >
                    <a class="dropdown-item" href="#"><FontAwesomeIcon icon={faAngleRight} />Quotes</a>
                  </NavLink>
                </div>
              </div>
            }

            {roleRights?.contactUs === true &&
              <NavLink
                to="/contact"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faBook} className="align-middle" />
                  <span className="align-middle">Contact Us</span>
                </span>
              </NavLink>
            }
            {roleRights?.termsCondition === true &&
              <NavLink
                to="/termsandconditions"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faBook} className="align-middle" />
                  <span className="align-middle">Terms and Condition</span>
                </span>
              </NavLink>
            }

            {roleRights?.privacyPolicy === true &&
              <NavLink
                to="/privacypolicy"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faBook} className="align-middle" />
                  <span className="align-middle">Privacy Policy</span>
                </span>
              </NavLink>
            }
            {roleRights?.about === true &&
              <NavLink
                to="/aboutus"
                className="sidebar-item"
                activeclassname="active"
              >
                <span className="sidebar-link">
                  <FontAwesomeIcon icon={faBook} className="align-middle" />
                  <span className="align-middle">About Us</span>
                </span>
              </NavLink>
            }
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
