import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  activeInactiveUser,
  getAllCorporateUserData,
} from "../../../redux/actions/getUsers";
import moment from "moment";
import ViewCorporateUser from "../Modals/ViewCorporateUser";
import { selectedCorporateUserData } from "../../../redux/actions/corporateUser";
import DeleteModal from "../Modals/DeleteModal";
import { deleteCorporateUser } from "../../../redux/actions/getUsers";
import ExportExcel from "../../Utils/ExportExcel";
import axios from "axios";
import EditCorporateUser from "../Modals/EditCorporateUser";
import AddCorporateUser from "../Modals/AddCorporateUser";
import { pick as _pick, map as _map } from "lodash";

function CorporateUsers() {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
  });
  const [show, setShowModal] = useState(false);
  const [showDel, setDeleteModal] = useState(false);
  const [rowUserId, setRowUserId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [edit, setEditModal] = useState(false);
  const [add, setAddModal] = useState(false);
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();
  const corporateUsers = useSelector((state) => state.users.corporateUser);

  const getHeaders = () => {
    const token = localStorage.getItem("token");
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "No-Auth-Challenge": true,
      },
    };
  };
  useEffect(() => {
    dispatch(getAllCorporateUserData(pagination));
    (async () => {
      const user = await axios.get(
        "https://apiyouni.rlogical.com/api/Authenticate/getCorporateUsers",
        getHeaders()
      );
      setData(user?.data?.data);
    })();
  }, [pagination]);

  const downloadData = _map(data, (obj) =>
    _pick(obj, [
      "userid",
      "corporateId",
      "username",
      "skypeName",
      "emailId",
      "loginType",
      "isActive",
    ])
  );

  const deleteUserData = () => {
    dispatch(deleteCorporateUser(rowUserId, { ...pagination, search: "" }));
    setDeleteModal(false);
  };

  const selectedCorporate = (row) => {
    dispatch(selectedCorporateUserData(row));
    setShowModal(true);
  };

  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(getAllCorporateUserData({ ...pagination, search: value }));
  };

  const editCorporateUserData = (row) => {
    setEditModal(true);
    dispatch(selectedCorporateUserData(row));
  };

  const handleIsActive = (e, row) => {
    if (row.userid) {
      setIsActive(true);
      dispatch(
        activeInactiveUser(
          {
            UserId: row?.userid,
            IsActive: e.target.checked,
          },
          pagination
        )
      );
    } else {
      setIsActive(false);
    }
  };

  const columns = [
    {
      name: "User Id",
      selector: (row) => row.userid,
      sortable: false,
      width: "90px",
    },
    {
      name: "User Name",
      selector: (row) => row.username,
      sortable: false,
    },
    {
      name: "User Image",
      selector: (row) => (
        <img
          src={row?.photo}
          onClick={() => window.open(row?.photo, "_blank")}
        />
      ),
      sortable: false,
    },
    {
      name: "Corporate Id",
      selector: (row) => row.corporateId,
      sortable: false,
    },
    {
      name: "Company Name",
      selector: (row) => row.companyName,
      sortable: false,
    },
    {
      name: "Skype Name",
      selector: (row) => row.skypeName,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.emailId,
      sortable: false,
    },
    {
      name: "Login Type",
      selector: (row) => row.loginType,
      sortable: false,
    },
    {
      name: "Status",
      width: "100px",
      selector: (row) => (
        <>
          {row?.userid && (
            <>
              <label class="switch">
                <input
                  type="checkbox"
                  checked={row.isActive ? isActive : false}
                  onChange={(e) => handleIsActive(e, row)}
                />
                <span class="slider round"></span>
              </label>
            </>
          )}
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            type="button"
            onClick={() => selectedCorporate(row)}
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            className="edit-cls"
            type="button"
            onClick={() => {
              editCorporateUserData(row);
            }}
            id={row.userid}
            data-mode="edit"
          >
            <FontAwesomeIcon
              icon={faEdit}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            className="delete-cls"
            type="button"
            onClick={() => {
              setDeleteModal(true);
              setRowUserId(row?.userid);
            }}
            id={row.userid}
            data-mode="delete"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Corporate User</strong> Listing
              </h1>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />
              <ExportExcel data={downloadData} fileName="CorporateUsers" />
              <button
                className="btn btn-primary"
                data-mode="add"
                onClick={() => {
                  setAddModal(true);
                }}
              >
                Add
              </button>
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={corporateUsers?.data}
              onChangeRowsPerPage={(newPageSize) =>
                setPagination({ ...pagination, pageSize: newPageSize })
              }
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(newPage) =>
                setPagination({ ...pagination, pageNumber: newPage })
              }
              pagination
              paginationTotalRows={corporateUsers?.totalRecords}
              paginationServer
              // selectableRows
              responsive
            />
          </div>
        </div>
      </main>
      <AddCorporateUser show={add} onHide={() => setAddModal(!add)} />
      <ViewCorporateUser show={show} onHide={() => setShowModal(!show)} />
      <EditCorporateUser show={edit} onHide={() => setEditModal(!edit)} />
      <DeleteModal
        show={showDel}
        deleteUserData={deleteUserData}
        onHide={() => setDeleteModal(!showDel)}
      />
    </>
  );
}

export default CorporateUsers;
