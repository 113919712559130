import React, { useState, useEffect } from "react";
import { Modal } from "../Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { addStaffData, updatStaffData } from "../../../../redux/actions/staff";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";

const AddStaff = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const [showPaw, setShowPaw] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const selectData = useSelector((state) => state?.staff?.selectedStaff);
  const isAddMode = !selectData?.staffId;
  console.log(selectData);
  const validationSchema = Yup.object().shape({
    emailId: Yup.string()
      .trim()
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        "Please enter valid email"
      )
      .required("Please enter email"),
    designation: Yup.string()
      .trim()
      .required("Please enter designation")
      .max(30, "Designation should be maximum 30 characters")
      .matches(/^[aA-zZ\s]+$/, "Designation allowed only alphabets"),
    gender: Yup.string().trim().required("Please select gender"),
    dob: Yup.string().trim().required("Please select birth date"),
    mobileNo: Yup.string()
      .trim()
      .required("Please enter mobile")
      .matches(/^[0-9\- ]{10}$/, "Please enter valid phone number"),
    userName: Yup.string()
      .trim()
      .required("Please enter username")
      .min(4, "Username should be minimum 4 characters")
      .max(30, "Username should be maximum 30 characters")
      .matches(/^[a-zA-Z0-9]+$/, "Special character not allowed"),
    password: Yup.string()
      .trim()
      .required("Please enter password")
      .min(6, "Password should be minimum 6 characters")
      .max(30, "Password should be maximum 30 characters")
      .matches(/(?=.*[0-9])/, "Password contains at least one digit"),
    education: Yup.string()
      .trim()
      .max(30, "Education should be maximum 30 characters")
      .required("Please enter education")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field"),
  });

  let initialValues = {
    userName: "",
    password: "",
    emailId: "",
    mobileNo: "",
    designation: "",
    gender: "",
    dob: "",
    education: "",
    showPassword: false,
  };

  // useEffect(() => {
  if (selectData?.staffId) {
    initialValues = selectData;
  }
  // },[selectData])

  const onSubmit = (values) => {
    if (selectData?.staffId) {
      const formData = new FormData();
      for (let key in values) {
        if (values[key] !== null) {
          formData.append(key, values[key]);
        }
      }
      dispatch(updatStaffData(formData));
      handleClose();
    } else {
      dispatch(addStaffData(values));
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch({ type: "CLEAR_STAFF_STATE" });
    onHide();
  };

  const handleClickShowPassword = () => {
    setShowPaw(!showPaw);
    if (type === "password") {
      setType("text");
      setIcon(eye);
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title={
          selectData?.staffId ? "Update Staff Details" : "Add Staff Details"
        }
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(value) => {
            onSubmit(value, validationSchema);
          }}
        >
          {({ errors, values, isSubmitting, touched, handleChange }) => {
            return (
              <Form autoComplete="off">
                <div className="form-row">
                  <div className="form-group col-12">
                    <label>Username</label>
                    <Field
                      name="userName"
                      type="text"
                      onChange={handleChange}
                      // value={selectData?.userName}
                      className={
                        "form-control" +
                        (errors.userName && touched.userName
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="userName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>Email</label>
                    <Field
                      name="emailId"
                      onChange={handleChange}
                      // value={selectData?.emailId}
                      type="text"
                      className={
                        "form-control" +
                        (errors.emailId && touched.emailId ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="emailId"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>Mobile</label>
                    <Field
                      name="mobileNo"
                      type="number"
                      onChange={handleChange}
                      // value={selectData?.mobileNo}
                      className={
                        "form-control" +
                        (errors.mobileNo && touched.mobileNo
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="mobileNo"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>Birth Date</label>
                    <Field
                      name="dob"
                      type="date"
                      onChange={handleChange}
                      value={
                        values?.dob && moment(values?.dob).format("YYYY-MM-DD")
                      }
                      // value={selectData?.dob && moment(selectData?.dob).format("YYYY-MM-DD")}
                      className={
                        "form-control" +
                        (errors.dob && touched.dob ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="dob"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label>Designation</label>
                    <Field
                      name="designation"
                      onChange={handleChange}
                      // value={selectData?.designation}
                      type="text"
                      className={
                        "form-control" +
                        (errors.designation && touched.designation
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="designation"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>Education</label>
                    <Field
                      name="education"
                      onChange={handleChange}
                      // value={selectData?.education}
                      type="text"
                      className={
                        "form-control" +
                        (errors.education && touched.education
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="education"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>Gender</label>
                    <Field
                      name="gender"
                      onChange={handleChange}
                      // value={selectData?.gender}
                      as="select"
                      className={
                        "form-control" +
                        (errors.gender && touched.gender ? " is-invalid" : "")
                      }
                    >
                      <option value="">Select gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Field>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label>Password</label>
                    <div className="password-wrapper">
                      <Field
                        name="password"
                        // value={selectData?.password}
                        type={showPaw ? "text" : "password"}
                        autocomplete="new-password"
                        className={
                          "form-control" +
                          (errors.password && touched.password
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <span
                        className="pass-show-icons"
                        onClick={handleClickShowPassword}
                      >
                        <Icon icon={icon} />
                      </span>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-12">
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      {selectData?.staffId ? "Update" : "Add"}
                    </Button>{" "}
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddStaff;
