import React from "react";
import { Modal } from "../Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";

const ViewTerms = ({ show, onHide }) => {

  const selectData = useSelector((state) => state?.termsAndCondition?.selectedTerms);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: "CLEAR_TERMS_STATE" })
    onHide();
  }

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View Terms and Condition Details"
        footer={<Button onClick={handleClose}>Cancel</Button>}
      >
        <div className="center">
          <p> Id : {selectData?.id}</p>
          <p> Terms : {selectData?.termsDetail}</p>
          {/* <p> Subject : {selectData?.subject}</p>
          <p> Message : {selectData?.message}</p> */}
          <p> Date : {moment(selectData?.createdDate).format('DD-MM-YYYY')}</p>
        </div>
        {/* <Button>Cancel</Button> */}
      </Modal>
    </>
  );
};

export default ViewTerms;
