import React, { useState, useEffect } from "react";
import AddQuotes from "../Modals/QuotesModal/AddQuotes";
import ViewQuotes from "../Modals/QuotesModal/ViewQuotes";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import DeleteModal from "../Modals/DeleteModal";
import {
  deleteQuotesData,
  getAllQuotesData,
  selectedQuotes,
} from "../../../redux/actions/quotes";

const Quotes = () => {
  const [addQuotes, setAddQuotes] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
  });
  const [searchText, setSearchText] = useState("");
  const [show, setShowModal] = useState(false);
  const [edit, setEditModal] = useState(false);
  const [shows, setDeleteModal] = useState(false);
  const [rowUserId, setRowUserId] = useState("");
  const dispatch = useDispatch();

  const QuotesData = useSelector((state) => state?.quotes?.quotes);

  useEffect(() => {
    dispatch(getAllQuotesData(pagination));
  }, [pagination]);

  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(getAllQuotesData({ ...pagination, search: value }));
  };

  const selectedQuotesData = (row) => {
    dispatch(selectedQuotes(row));
    setShowModal(true);
  };

  const addQuotesData = (row) => {
    setAddQuotes(true);
    dispatch(selectedQuotes(row));
  };

  const deleteUserData = () => {
    dispatch(deleteQuotesData(rowUserId, { ...pagination, search: "" }));
    setDeleteModal(false);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.quoteId,
      sortable: false,
      width: "100px",
    },
    {
      name: "Quotes",
      selector: (row) => row.quote,
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) => (row.isActive === true ? "Active" : "InActive"),
      sortable: false,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdDate).format("DD-MM-YYYY"),
      sortable: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            type="button"
            onClick={() => selectedQuotesData(row)}
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            className="edit-cls"
            type="button"
            onClick={() => addQuotesData(row)}
            id={row.quoteId}
            data-mode="edit"
          >
            <FontAwesomeIcon
              icon={faEdit}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            type="button"
            className="delete-cls"
            id={row.quoteId}
            onClick={() => {
              setDeleteModal(true);
              setRowUserId(row?.quoteId);
            }}
            data-mode="delete"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Quotes</strong> Listing
              </h1>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />{" "}
              <button
                className="btn btn-primary"
                data-mode="add"
                onClick={addQuotesData}
              >
                Add
              </button>
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={QuotesData.data}
              onChangeRowsPerPage={(newPageSize) =>
                setPagination({ ...pagination, pageSize: newPageSize })
              }
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(newPage) =>
                setPagination({ ...pagination, pageNumber: newPage })
              }
              pagination
              paginationTotalRows={QuotesData?.totalRecords}
              paginationServer
              //   selectableRows
              responsive
            />
          </div>
        </div>
      </main>
      <ViewQuotes show={show} onHide={() => setShowModal(!show)} />
      <AddQuotes show={addQuotes} onHide={() => setAddQuotes(!addQuotes)} />
      <DeleteModal
        deleteUserData={deleteUserData}
        show={shows}
        onHide={() => setDeleteModal(!shows)}
      />
    </>
  );
};

export default Quotes;
