import axios from "axios";
import {
  addCorporateuser,
  getAllCororateCompany,
  getUsersAllData,
  deleteCompany,
  updateCorporateCompany,
  activeCompany,
} from "../../api/api";
import * as type from "../type";
import { API_URL } from "../../api/api";
import { toast } from "react-toastify";

// const successCorporateCompnay = (company) => {
//   return {
//     type: type.CORPORATE_COMPANY_SUCCESS,
//     payload: company,
//   };
// };

const failureCorporateCompnay = (error) => {
  return {
    type: type.CORPORATE_COMPANY_FAILURE,
    message: error,
  };
};

const requestCorpoUsersData = () => {
  return {
    type: type.ADD_CORPORATE_REQUEST,
  };
};

const successCorpoUsers = (corporate) => {
  return {
    type: type.ADD_CORPORATE_SUCCESS,
    payload: corporate,
  };
};

const errorCorpoUsers = (error) => {
  return {
    type: type.ADD_CORPORATE_FAILURE,
    message: error,
  };
};

export const selectedCompanyData = (company) => {
  return {
    type: type.SELECTED_COMPANY_SUCCESS,
    payload: company,
  };
};

export const selectedCorporateUserData = (corporate) => {
  return {
    type: type.SELECTED_CORPORATE_USER_SUCCESS,
    payload: corporate,
  };
};
const updateCompanyData = (data) => ({
  type: type.UPDATE_COMPANY,
  data,
});

export const addCorporateUser = (corporate, handleClose) => {
  return function (dispatch) {
    dispatch(requestCorpoUsersData());
    const postData = JSON.stringify(corporate);
    axios({
      method: "post",
      url: `${API_URL}Company/AddCompany`,
      data: postData,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        if (response.data) {
          successCorpoUsers(response.data);
          toast.success("Company Added Successfully");
          handleClose();
        }
      })
      .catch(function (response) {
        const erroMessage = response.response.data;
        if (erroMessage.code === 400) {
          toast.error(erroMessage.message);
        }
        failureCorporateCompnay(erroMessage.message);
      });
  };
};

export const getAllCorporateCompanyactionfnc = (page) => {
  return async (dispatch) => {
    const notes = await getAllCororateCompany(page);
    dispatch({
      type: type.CORPORATE_COMPANY_SUCCESS,
      payload: notes.data,
    });
  };
};

export const editCorporateCompany = (body, hideModal) => {
  return (dispatch) => {
    return updateCorporateCompany(body)
      .then((res) => {
        dispatch(updateCompanyData(res.data));
        dispatch(
          getAllCorporateCompanyactionfnc({
            pageNumber: 1,
            pageSize: 10,
            search: "",
          })
        );
        toast.success("Company Updated Successfully");
        hideModal();
      })
      .catch((err) => {});
  };
};

export const activeInactiveCompany = (userId, pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.ACTIVE_COMPANY_REQUEST,
    });
    return activeCompany(userId)
      .then((res) => {
        dispatch({
          type: type.ACTIVE_COMPANY_SUCCESS,
          payload: res,
          data: userId,
        });
        dispatch(getAllCorporateCompanyactionfnc(pageObj));
        toast.success(
          `Company ${userId.IsActive ? "activate" : "deactivate"} successfully`
        );
      })
      .catch((err) => {
        dispatch({
          type: type.ACTIVE_COMPANY_FAILURE,
        });
        toast.error("Something went wrong");
      });
  };
};

export const deleteCompanyData = (userDelete, pageObj) => {
  return (dispatch) => {
    dispatch({
      type: type.DELETE_CORPORATE_COMPANY_REQUEST,
    });
    return deleteCompany(userDelete)
      .then((res) => {
        toast.success("Corporate Company Deleted Successfully");
        dispatch({
          type: type.DELETE_CORPORATE_COMPANY_SUCCESS,
          payload: res,
          data: userDelete,
        });
        dispatch(getAllCorporateCompanyactionfnc(pageObj));
      })
      .catch((err) => {
        dispatch({
          type: type.DELETE_CORPORATE_COMPANY_FAILURE,
        });
      });
  };
};
