import React from "react";
import { Modal } from "./Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import moment from "moment";

const ViewUserModal = ({ show, onHide }) => {
  const selectData = useSelector((state) => state?.users?.selectedUser);

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View User Details"
        footer={<Button onClick={onHide}>Cancel</Button>}
      >
        <div className="center">
          <p> User Id : {selectData?.userid}</p>
          <p> Username : {selectData?.username}</p>
          <p> Skype Name : {selectData?.skypeName}</p>
          <p> Email : {selectData?.emailId}</p>
          {/* <p> Login Type : {selectData?.loginType}</p> */}
          <p>
            {" "}
            Status : {selectData?.isActive === true ? "Active" : "InActive"}
          </p>
          <p>
            {" "}
            User Type : {selectData?.userTypeId === 2 ? "Normal" : "Corporate"}
          </p>
          <p> Address : {selectData?.address}</p>
        </div>
        {/* <Button>Cancel</Button> */}
      </Modal>
    </>
  );
};

export default ViewUserModal;
