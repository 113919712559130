import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  activeInactiveCompany,
  getAllCorporateCompanyactionfnc,
} from "../../../redux/actions/corporateUser";
import ViewCorporateCompany from "../Modals/ViewCorporateComapny";
import CorporateModal from "../Modals/CorporateModal";
import { selectedCompanyData } from "../../../redux/actions/corporateUser";
import DeleteModal from "../Modals/DeleteModal";
import { deleteCompanyData } from "../../../redux/actions/corporateUser";
import ExportExcel from "../../Utils/ExportExcel";
import axios from "axios";
import EditCorporateCompany from "../Modals/EditCorporateCompany";
import { pick as _pick, map as _map } from "lodash";

function CorporateCompany() {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    search: "",
  });
  const prevRef = useRef();
  const [show, setShowModal] = useState(false);
  const [shows, setDataModal] = useState(false);
  const [edit, setEditModal] = useState(false);
  const [showDel, setDeleteModal] = useState(false);
  const [rowUserId, setRowUserId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [mode, setModalMode] = useState("");
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();

  const allCompnay = useSelector((state) => state.corporateCompany.company);

  useEffect(() => {
    dispatch(getAllCorporateCompanyactionfnc(pagination));
    (async () => {
      const user = await axios.get(
        "https://apiyouni.rlogical.com/api/Company/getCompanies"
      );
      setData(user?.data?.data);
    })();
  }, [dispatch.apply, show, pagination]);

  const downloadData = _map(data, (obj) =>
    _pick(obj, [
      "companyId",
      "corporateId",
      "maxUsers",
      "companyName",
      "createdDate",
      "description",
      "isActive",
    ])
  );

  const addCorporateUser = (e) => {
    var mode = e.currentTarget.dataset.mode;

    setShowModal(true);
    setModalMode(mode);
  };

  const modalClode = (e) => {
    setShowModal(false);
  };

  const handleIsActive = (e, row) => {
    if (row.companyId) {
      setIsActive(true);
      dispatch(
        activeInactiveCompany(
          {
            companyId: row?.companyId,
            IsActive: e.target.checked,
          },
          pagination
        )
      );
    } else {
      setIsActive(false);
    }
  };

  const deleteUserData = () => {
    dispatch(deleteCompanyData(rowUserId, pagination));
    setDeleteModal(false);
  };

  const selectedCompany = (row) => {
    dispatch(selectedCompanyData(row));
    setDataModal(true);
  };

  const requestSearch = (event) => {
    event.preventDefault();
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    dispatch(getAllCorporateCompanyactionfnc({ ...pagination, search: value }));
  };

  const editcompanyData = (row) => {
    setEditModal(true);
    dispatch(selectedCompanyData(row));
  };

  const columns = [
    {
      name: "Corporate Id",
      selector: (row) => row.corporateId,
      sortable: false,
    },
    {
      name: "Company Name",
      selector: (row) => row.companyName,
      sortable: false,
    },
    {
      name: "Maximum User",
      selector: (row) => row.maxUsers,
      sortable: false,
    },
    {
      name: "Active User",
      selector: (row) => row.activeUserCount,
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row?.companyId && (
            <>
              <label class="switch">
                <input
                  type="checkbox"
                  checked={row.isActive ? isActive : false}
                  onChange={(e) => handleIsActive(e, row)}
                />
                <span class="slider round"></span>
              </label>
            </>
          )}
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span
            className="view-cls"
            type="button"
            onClick={() => selectedCompany(row)}
            id={row.id}
            data-mode="view"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            className="edit-cls"
            type="button"
            onClick={() => {
              editcompanyData(row);
            }}
            id={row.userid}
            data-mode="edit"
          >
            <FontAwesomeIcon
              icon={faEdit}
              className="align-middle"
              size="lg"
              border
            />
          </span>
          <span
            className="delete-cls"
            type="button"
            onClick={() => {
              setDeleteModal(true);
              setRowUserId(row?.companyId);
            }}
            id={row?.companyId}
            data-mode="delete"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="align-middle"
              size="lg"
              border
            />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h1 className="h3 mb-3">
                <strong>Corporate Company</strong> Listing
              </h1>
            </div>
            <div className="col-auto ms-auto text-end mt-n1">
              <input
                type="text"
                placeholder="Search here..."
                autoFocus
                value={searchText}
                onChange={(event) => requestSearch(event)}
              />
              <ExportExcel data={downloadData} fileName="CorporateCompanys" />
              <button
                className="btn btn-primary"
                data-mode="add"
                onClick={addCorporateUser}
              >
                Add
              </button>
            </div>
          </div>
          <div className="row">
            <DataTable
              columns={columns}
              data={allCompnay?.data}
              onChangeRowsPerPage={(newPageSize) =>
                setPagination({ ...pagination, pageSize: newPageSize })
              }
              paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(newPage) =>
                setPagination({ ...pagination, pageNumber: newPage })
              }
              pagination
              paginationTotalRows={allCompnay?.totalRecords}
              paginationServer
              // selectableRows
              responsive
            />
          </div>
        </div>
      </main>
      <ViewCorporateCompany show={shows} onHide={() => setDataModal(!shows)} />
      <EditCorporateCompany
        show={edit}
        onHide={() => setEditModal(!edit)}
        editcompanyData={editcompanyData}
      />
      <CorporateModal show={show} mode={mode} handleClose={setShowModal} />
      <DeleteModal
        show={showDel}
        deleteUserData={deleteUserData}
        onHide={() => setDeleteModal(!showDel)}
      />
    </>
  );
}

export default CorporateCompany;
