import React, { useState, useEffect } from 'react'
import { Modal } from '../Modal'
import Button from 'react-bootstrap/Button';
import isEmpty from '../../validation/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../../../../firebase-config';
import { addBlogData, updateBlogData } from '../../../../redux/actions/ourBlogs';
import { addCommunityData, updatCommunityData } from '../../../../redux/actions/ourCommunity';
import { toast } from "react-toastify";

const AddCommunity = ({ show, onHide }) => {

  const [communityDescErr, setCommunityDescErr] = useState('')
  const initialValues = {
    imageUrl1: "",
    imageUrl2: "",
    imageUrl3: "",
    imageUrl4: "",
    communityDesc: "",
    isActive: false,
  };

  const [state, setState] = useState({ ...initialValues })

  const [image, setImage] = useState(null)
  const [imageSecond, setImageSecond] = useState(null)
  const [imageThird, setImageThird] = useState(null)
  const [imageFourth, setImageFourth] = useState(null)
  const [percent, setPercent] = useState(0)
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const selectData = useSelector((state) => state?.ourCommunity?.selectedCommunity);

  useEffect(() => {
    setState(selectData);
  }, [selectData]);
  const handleChange = (e) => {

    if (e.target.name === "communityDesc") {
      setCommunityDescErr("");
    }

    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    if (isEmpty(state?.communityDesc)) {
      setCommunityDescErr("Please enter description");
      isValid = false;
    }


    return isValid;
  };

  const handleImage1Change = (e) => {
    setLoader(true);
    const file = e.target.files[0];
    if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
      toast.error("This file format is not supported");
      setLoader(false);
  } else {
    setImage(file);
    file["id"] = Math.random();
    const promises = [];
    const storageRef = ref(storage, `/community/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    promises.push(uploadTask);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          let URLS = url;
          if (URLS) {
            setImage(URLS);
            setLoader(false);
          }
          setState({ ...state, ["imageUrl1"]: URLS });
        });
      }
    );}
  };
  const handleImage2Change = (e) => {
    setLoader(true);
    const file = e.target.files[0];
    if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
      toast.error("This file format is not supported");
      setLoader(false);
  } else {
    setImageSecond(file);
    file["id"] = Math.random();
    const promises = [];
    const storageRef = ref(storage, `/community/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    promises.push(uploadTask);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          let URLS = url;
          if (URLS) {
            setImageSecond(URLS);
            setLoader(false);
          }
          setState({ ...state, ["imageUrl2"]: URLS });
        });
      }
    );}
  };

  const handleImage3Change = (e) => {
    setLoader(true);
    const file = e.target.files[0];
    if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
      toast.error("This file format is not supported");
      setLoader(false);
  } else {
    setImageThird(file);
    file["id"] = Math.random();
    const promises = [];
    const storageRef = ref(storage, `/community/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    promises.push(uploadTask);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          let URLS = url;
          if (URLS) {
            setImageThird(URLS);
            setLoader(false);
          }
          setState({ ...state, ["imageUrl3"]: URLS });
        });
      }
    );}
  };

  const handleImage4Change = (e) => {
    setLoader(true);
    const file = e.target.files[0];
    if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
      toast.error("This file format is not supported");
      setLoader(false);
  } else {
    setImageFourth(file);
    file["id"] = Math.random();
    const promises = [];
    const storageRef = ref(storage, `/community/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    promises.push(uploadTask);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          let URLS = url;
          if (URLS) {
            setImageFourth(URLS);
            setLoader(false);
          }
          setState({ ...state, ["imageUrl4"]: URLS });
        });
      }
    );}
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid && !selectData?.id) {
      const data = {
        communityDesc: state?.communityDesc,
        imageUrl1: state?.imageUrl1,
        imageUrl2: state?.imageUrl2,
        imageUrl3: state?.imageUrl3,
        imageUrl4: state?.imageUrl4,
        isActive: state?.isActive ? state?.isActive : false,
        createdBy: 0,
      }
      dispatch(addCommunityData(data))
      handleClose()
    } else if (isValid) {
      const data = {
        Id: state?.id,
        CommunityDesc: state?.communityDesc,
        ImageUrl1: state?.imageUrl1,
        ImageUrl2: state?.imageUrl2,
        ImageUrl3: state?.imageUrl3,
        ImageUrl4: state?.imageUrl4,
        IsActive: state?.isActive,
        UpdatedBy: 1,
      }
      const formData = new FormData();
      for (let key in data) {
        if (data[key] !== null) {
          formData.append(key, data[key]);
        }
      }
      dispatch(updatCommunityData(formData))
      setImage('')
      handleClose()
    }

  }

  const handleClose = () => {
    dispatch({ type: "CLEAR_COMMUNITY_STATE" })
    setCommunityDescErr("")
    onHide();
  }

  const handleActive = (event) => {
    if (event.target.checked) {
      setState({ ...state, ["isActive"]: true });
    } else {
      setState({ ...state, ["isActive"]: false });
    }
  }

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow common-popup-wrapper"
        onHide={onHide}
        title={state?.id ? "Update Community" : "Add Community"}
        footer={
          <>
            <Button onClick={handleSubmit}>{state?.id ? "Update" : "Add"}</Button>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          </>}
      >
        <div className="mb-4">
          <label className="form-label">Description</label>
          <input
            className='form-control form-control-lg'
            name="communityDesc"
            placeholder='Please enter description'
            value={state?.communityDesc}
            onChange={handleChange}
          />
          {communityDescErr && <span className="error-msg">{communityDescErr}</span>}
        </div>

        <div className='mb-4'>
          <div className='fit-image'>
            <img src={state?.imageUrl1} />
            <div className="input_file">
              <input
                className="edit-file"
                type="file"
                id="file"
                accept="image/*"
                name="imageUrl"
                onChange={handleImage1Change}
              />
              Upload Image
            </div>
            {loader && (
              <>
                {" "}
                <div className="cssloader_main">
                  {" "}
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>{" "}
                  </div>{" "}
                </div>
              </>
            )}
          </div>
        </div>
        <div className='mb-4'>
          <div className='fit-image'>
            <img src={state?.imageUrl2} />
            <div className="input_file">
              <input
                className="edit-file"
                type="file"
                id="file"
                accept="image/*"
                name="imageUrl"
                onChange={handleImage2Change}
              />
              Upload Image
            </div>
            {loader && (
              <>
                {" "}
                <div className="cssloader_main">
                  {" "}
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>{" "}
                  </div>{" "}
                </div>
              </>
            )}
          </div>
        </div>
        <div className='mb-4'>
          <div className='fit-image'>
            <img src={state?.imageUrl3} />
            <div className="input_file">
              <input
                className="edit-file"
                type="file"
                id="file"
                accept="image/*"
                name="imageUrl"
                onChange={handleImage3Change}
              />
              Upload Image
            </div>
            {loader && (
              <>
                {" "}
                <div className="cssloader_main">
                  {" "}
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>{" "}
                  </div>{" "}
                </div>
              </>
            )}
          </div>
        </div>
        <div className='mb-4'>
          <div className='fit-image'>
            <img src={state?.imageUrl4} />
            <div className="input_file">
              <input
                className="edit-file"
                type="file"
                id="file"
                accept="image/*"
                name="imageUrl"
                onChange={handleImage4Change}
              />
              Upload Image
            </div>
          </div>
          {loader && (
            <>
              {" "}
              <div className="cssloader_main">
                {" "}
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>{" "}
                </div>{" "}
              </div>
            </>
          )}
        </div>
        <div className="mb-4">
          <label className="form-label">Active </label>{" "}
          <label class="switch">
            <input type="checkbox"
              checked={
                state?.isActive
                  ? state?.isActive
                  : false
              }
              onChange={handleActive} />
            <span class="slider round"></span>
          </label>
        </div>
      </Modal>
    </>
  )
}

export default AddCommunity