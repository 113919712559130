import React from "react";
import { Modal } from "../Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";

const ViewPrivacy = ({ show, onHide }) => {

  const selectData = useSelector((state) => state?.privacyPolicy?.selectedPrivacy);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({type: "CLEAR_PRIVACY_STATE"})
    onHide()
  }

  return (
    <>
      <Modal
        show={show}
        className="modal fade theme_modal follow"
        onHide={onHide}
        title="View Privacy Policy Details"
        footer={<Button onClick={handleClose}>Cancel</Button>}
      >
        <div className="center">
          <p> Id : {selectData?.id}</p>
          <p> Terms : {selectData?.policyDetail}</p>
          {/* <p> Subject : {selectData?.subject}</p>
          <p> Message : {selectData?.message}</p> */}
          <p> Date : {moment(selectData?.createdDate).format('DD-MM-YYYY')}</p>
        </div>
        {/* <Button>Cancel</Button> */}
      </Modal>
    </>
  );
};

export default ViewPrivacy;
